<template>
  <div id="mindo">
    <p class="text-center mb-0 pb-3 pt-3 mindo-badge bg-orange">
      <PersonhandSvg />
      <span>MİN</span><span class="text-white">DO</span><br />
      <br />
      <span style="font-size: 20px">Birlikte cevap bulalım!</span>
    </p>
    <div
      class="mindo active cursor-pointer"
      v-for="item in getMindoQuestions"
      :key="item.Id"
    >
      <a @click="goToMindo(item.Id)" class="icon-right">
        <span style="font-style: italic">
          <strong>{{ item.MindoAccountName }} :</strong>
        </span>
        <br />
        <p>{{ item.Name }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import PersonhandSvg from "./PersonhandSvg.vue";
export default {
  name: "MindoCard",
  components: {
    PersonhandSvg,
  },
  methods: {
    goToMindo(query) {
      this.$router.push({ path: "/mindo", query: { question: query } });
    },
  },
  computed: {
    getMindoQuestions() {
      return this.$store.getters["mindo/getMindoQuestions"];
    },
  },
  async created() {
    if (!this.getMindoQuestions) {
      await this.$store.dispatch("mindo/setMindoQuestions");
    }
  },
};
</script>

<style></style>
