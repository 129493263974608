<template>
  <button
    id="btn-share-invendo"
    class="
      btn-innovation btn-innovation-left
      icon-briefcase
      bg-orange
      d-lg-inline-block
      tooltipper
    "
    @click.stop="shareInvendoModal"
    title="Kurum İçi Girişimcilik Programı kapsamında değerlendirilmesini istediğin yeni iş fikirlerini buradan iletebilirsin."
  >
    {{userDetail.CompanyConfiguration.InvendoButtonText}}
    <span class="icon-right"></span>
    <span
      style="
        color: #fff;
        font-size: 13px;
        display: block;
        opacity: 78%;
        font-weight: 400;
      "
      >Kurum İçi Girişimcilik Programı</span
    >
  </button>
</template>

<script>
export default {
  name: "InvendoIdea",
  props:["userDetail"],
  methods: {
    shareInvendoModal() {
      this.$emit("shareInvendoModal", true);
    },
  },
};
</script>

<style>
</style>