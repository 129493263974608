<template>
  <div id="leader-board" class="d-none d-lg-block pb-2 pt-1 px-3 bg-white">
    <p>
      En Çok Fikir Veren Kişiler
      <MedalSvg />
    </p>

    <div
      class="d-flex my-0 mx-3 py-4 px-1 align-items-center"
      v-for="item in mostSuggestionUsersData"
      :key="item.UserId"
    >
      <img
        v-if="item.UserPictureId != 0"
        class="flex-shrink-0 fallback-image"
        :src="`data:image/png;base64, ${
          item.UserProfileImage ? item.UserProfileImage : ''
        }`"
        :alt="item.UserName | getInitialsFilter"
      />
      <i
        v-else
        class="flex-shrink-0 fallback-image"
        :alt="item.UserName | getInitialsFilter"
        >{{ item.UserName | getInitialsFilter }}</i
      >
      <div>
        <span class="d-block">{{ item.UserName }} </span>
        <span class="d-block">{{ item.CompanyName }}</span>
        <span class="d-block">Fikir Sayısı: {{ item.SuggestionCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import MedalSvg from "./MedalSvg.vue";
import { getInitials } from "../../helpers";
export default {
  name: "PeopleIdea",
  data() {
    return {};
  },
  components: {
    MedalSvg,
  },
  filters: {
    getInitialsFilter(value) {
      return getInitials(value);
    },
  },
  computed: {
    mostSuggestionUsersData() {
      return this.$store.getters["mostSuggestionUsers/mostSuggestionUsersData"];
    },
  },
  async created() {
    if (!this.mostSuggestionUsersData) {
      await this.$store.dispatch("mostSuggestionUsers/setMostSuggestionUsers");
    }
  },
};
</script>

<style></style>
