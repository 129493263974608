<template>
  <button
    v-if="user.Data.CompanyConfiguration.IsSuggestionVisible"
    @click.stop="$emit('share-idea', true)"
    id="btn-share-idea"
    class="btn-innovation btn-innovation-left bg-orange d-lg-inline-block icon-right tooltipper"
    title="Şirket içi uygulamalar veya iş süreçlerinize ilişkin her türlü fikrinizi buradan paylaşabilirsiniz."
  >
    {{ user.Data.CompanyConfiguration.SuggestionButtonText }}
    <img
      src="../../assets/images/fp_icon.png"
      alt="Fikrini Paylaş"
      style="padding-right: 40px; position: absolute; right: 0; top: 5px"
    />
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShareIdea",
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
};
</script>

<style></style>
