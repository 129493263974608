<template>
  <section id="slider-card" class="slider-card__wrapper">
    <article class="" type="Announcement" objectid="142757b2-a436-e911-a96b-000d3aba0f22">
      <div style="height: 330px">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->

          <!-- Slides with custom text -->
          <a
            v-for="item in getSliderPicture"
            :key="item.Url"
            :href="item.RedirectUrl ?? '#'"
            :target="item.RedirectUrl ? '_blank' : '_self'"
          >
            <b-carousel-slide :img-src="item.Url">
              <h1>{{ item.Descreption }}</h1>
            </b-carousel-slide>
          </a>
        </b-carousel>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: "SliderCard",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  props:{
    getSliderPicture:{
      type: Array,
      default:()=>[]
    }
  },
  methods: {
    onSlideStart(slide) {
      this.slide = slide;
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.slide = slide;
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/scss/slider_card.scss";
</style>
