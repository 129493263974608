<template>
  <div id="modalDetail">
    <div
      id="modal-idea-detail"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" ref="modalContent">
          <div class="modal-header bg-orange rounded-0">
            <h5 class="modal-title text-center text-white mx-auto">
              FİKİR DETAYI
            </h5>
            <button
              type="button"
              class="close"
              id="btnDetailClose"
              data-dismiss="modal"
              aria-label="Close"
              @click="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="container mb-3 mw-100">
            <div class="row pl-3 pl-sm-5 mb-2 modal-bar">Fikir Bilgileri</div>
            <dl class="row px-3 px-sm-5 pt-3 mb-0">
              <dt>Fikir No</dt>
              <dd>{{ detailData.SuggestionStatusId != 11 ? detailData.SuggestionNumber : ""}}</dd>
              <dt>Şirket</dt>
              <dd>{{ detailData.CompanyName }}</dd>
              <dt>Fikri Yapan</dt>
              <dd class="text-uppercase">
                {{ detailData.CreatedCustomerName }} (
                {{ detailData.CreatedCustomerCompanyName }} )
              </dd>
              <dt>Bölge</dt>
              <dd>{{ detailData.OfficeLocation }}</dd>
              <dt>Fikir Tarihi</dt>
              <dd>{{ detailData.SuggestionDate | newDateFormatterFilter }}</dd>

              <dt>Departman</dt>
              <dd>{{ detailData.Department }}</dd>
              <dt>Görev</dt>
              <dd>{{ detailData.JobTitle }}</dd>
              <dt>Statü</dt>
              <dd>
                {{ detailData.SuggestionStatusName }}
              </dd>

              <dt v-if="detailData.SuggestionStatusName == 'İptal Edildi'">İptal Nedeni</dt>
              <dd v-if="detailData.SuggestionStatusName == 'İptal Edildi'" class="single">
                {{ detailData.ProjectCancelledReason }}
              </dd>

              <dt>Değer Akımı</dt>
              <dd class="single">{{ detailData.ValueCurrentName }}</dd>

              <dt>Sorun / Fırsat</dt>
              <dd class="single">{{ detailData.Problem }}</dd>
              <dt>Fikir</dt>
              <dd class="single">{{ detailData.SuggestionDetail }}</dd>
              <dt>Beklenen Fayda</dt>
              <dd class="single">{{ detailData.ExpectedBenefit }}</dd>

              <dt v-if="detailData.SuggestionStatusId == '4'">
                Fikir Ret Açıklaması
              </dt>
              <dd v-if="detailData.SuggestionStatusId == '4'">
                {{ detailData.CancelDescription }}
              </dd>

              <dt v-if="detailData.SuggestionStatusId == '4'">
                Fikir Ret Nedeni
              </dt>
              <dd v-if="detailData.SuggestionStatusId == '4'">
                {{ detailData.CancelReasonName }}
              </dd>

              <dt>Doküman</dt>
              <dd class="single">
                <div
                  class="form-group custom-file icon-folder-alt"
                  v-if="detailData && detailData.DownloadFile.length > 0"
                >
                  <p
                    class="
                      form-control
                      d-flex
                      align-items-center
                      cursor-default
                    "
                    v-for="item in detailData.DownloadFile"
                    :key="item.Id"
                  >
                    <span
                      class="
                        flex-shrink-1
                        mr-1
                        hide-overflow-text
                        cursor-default
                      "
                      >{{ item.Filename }}.{{ item.Extension }}</span
                    >
                    <a
                      :href="
                        'data:' +
                        item.fileType +
                        ';base64,' +
                        item.DownloadBinary
                      "
                      :download="item.Filename + '.' + item.Extension"
                      class="
                        btn btn-light
                        text-blue-1
                        ml-auto
                        fas
                        fa-file-download
                      "
                      title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      target="_blank"
                    >
                    </a>
                  </p>
                </div>
              </dd>
              <dt
                v-if="
                  detailData &&
                  detailData.ProjectPresentationDocumentFiles.length > 0
                "
              >
                Proje Bildirisi
              </dt>
              <dd
                class="single"
                v-if="
                  detailData &&
                  detailData.ProjectPresentationDocumentFiles.length > 0
                "
              >
                <div
                  class="form-group custom-file icon-folder-alt"
                  v-for="item in detailData.ProjectPresentationDocumentFiles"
                  :key="item.Id"
                >
                  <p
                    class="
                      form-control
                      d-flex
                      align-items-center
                      cursor-default
                    "
                  >
                    <span
                      class="
                        flex-shrink-1
                        mr-1
                        hide-overflow-text
                        cursor-default
                      "
                      >{{ item.Filename }}.{{ item.Extension }}</span
                    >
                    <a
                      :href="
                        'data:' +
                        item.fileType +
                        ';base64,' +
                        item.DownloadBinary
                      "
                      :download="item.Filename + '.' + item.Extension"
                      class="
                        btn btn-light
                        text-blue-1
                        ml-auto
                        fas
                        fa-file-download
                      "
                      title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      target="_blank"
                    >
                    </a>
                  </p>
                </div>
              </dd>
            </dl>

            <div class="row pl-3 pl-sm-5 mb-2 modal-bar">
              Etkileşim Bilgileri
            </div>
            <div class="row px-3 px-sm-5">
              <a
                @click="isComment = !isComment"
                class="article-comment-show icon-down"
                id="commentCount"
                >{{ detailData.CommentCount }} Yorum</a
              >
              <span class="article-comment-like">
                <i class="fas fa-thumbs-up">{{ detailData.LikeCount }}</i>
              </span>
              <button
                @click.stop="supportShowModal(detailData.Id)"
                class="btn-article-action icon-arrow-right"
                data-toggle="modal"
                data-target="#modal-support"
              >
                <span
                  class="d-none d-sm-inline tooltipper"
                  title="Destek iste tooltip"
                  >Destek İste</span
                >
              </button>

              <article
                v-if="isComment"
                class="article-comment-container"
                type="Suggestion"
                objectid="718d7c1f-7dec-eb11-bacb-000d3ade7034"
              >
                <div id="suggestiondetailcomments">
                  <div
                    id="commentdiv"
                    v-for="comment in detailData.Comments"
                    :key="comment.Id"
                  >
                    <div
                      class="article-comment"
                      id="article-comment-id"
                      style="position: static"
                    >
                      <div class="article-comment-body">
                        <img
                          v-if="comment.UserProfileImage"
                          class="comment-user-img fallback-image"
                          :src="`data:image/png;base64,${comment.UserProfileImage.DownloadBinary}`"
                          :alt="comment.UserName | getInitialsFilter"
                        />
                        <i
                          v-else
                          class="comment-user-img fallback-image"
                          :alt="comment.UserName | getInitialsFilter"
                          >{{ comment.UserName | getInitialsFilter }}</i
                        >
                        <span
                          >{{ comment.UserName }} (
                          {{ comment.UserCompanyName }} )</span
                        ><span
                          class="icon-clock"
                          :title="comment.CreatedOnUtc"
                          >{{ comment.CreatedOnUtc | dateDiffFilter }}</span
                        >
                        <p
                          style="
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                          "
                        >
                          {{ comment.CommentText }}
                        </p>
                      </div>
                      <div
                        class="article-comment-container"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div class="row pl-5 mb-2 modal-bar">Fikir Dağıtım Bilgileri</div>
            <dl class="row px-5 pt-3 mb-0">
              <dt>Fikir Dağıtıcısı</dt>
              <dd>
                {{ detailData.SuggestionExecutiveName }} (
                {{ detailData.SuggestionExecutiveCompanyName }} )
              </dd>

              <dt>İşlem Tarihi</dt>
              <dd>
                {{
                  detailData.SuggestionExecutiveProcessDate
                    | newDateFormatterFilter
                }}
              </dd>

              <dt>Fikir Dağıtıcısı Notu</dt>
              <dd class="single">{{ detailData.SuggestionExecutiveNote }}</dd>
            </dl>

            <div
              class="row pl-5 mb-2 modal-bar"
              v-if="detailData.SuggestionEvaluatorId != 0"
            >
              Fikir Değerlendirme Bilgileri
            </div>
            <dl
              class="row px-5 pt-3 mb-0"
              v-if="detailData.SuggestionEvaluatorId != 0"
            >
              <dt>Fikir Değerlendirici</dt>
              <dd>
                {{ detailData.SuggestionEvaluatorName }} (
                {{ detailData.SuggestionEvaluatorCompanyName }} )
              </dd>

              <dt>İşlem Tarihi</dt>
              <dd>
                {{
                  detailData.SuggestionEvaluatorProcessDate
                    | newDateFormatterFilter
                }}
              </dd>

              <dt>Fikir Değerlendirme Açıklaması</dt>
              <dd class="single">{{ detailData.SuggestionEvaluatorNote }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <SuggestionSupport
        v-if="isSuggestionSupport"
        @close="isSuggestionSupport = $event"
        :suggestionId="suggestionId"
      />
    </transition>
  </div>
</template>

<script>
import { upload } from "../../../services";
import SuggestionSupport from "../../Main/Cards/Modals/SuggestionSupport.vue";
import {
  suggestionStatus,
  dateFormatter,
  getInitials,
  newDateFormatter,
  dateDiff,
} from "../../../helpers";
export default {
  name: "IdeaModal",
  data() {
    return {
      isComment: false,
      isSuggestionSupport: false,
    };
  },
  components: {
    SuggestionSupport,
  },
  props: ["detailData"],
  filters: {
    suggestionStatusFilter(value) {
      return suggestionStatus(value);
    },
    dateFormatterFilter(value) {
      return dateFormatter(value);
    },
    dateDiffFilter(value) {
      return dateDiff(value);
    },
    newDateFormatterFilter(value) {
      return newDateFormatter(value);
    },
    getInitialsFilter(value) {
      return getInitials(value);
    },
  },
  async created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    if (this.detailData.Comments && this.detailData.Comments.length > 0) {
      for await (let comment of this.detailData.Comments) {
        const res = await upload.fetchProfileImage(comment.UserPictureId);
        comment.UserProfileImage = {
          DownloadBinary: res.Data.DownloadBinary,
          Extension: res.Data.Extension,
          Filename: res.Data.Filename,
          Id: res.Data.Id,
        };
      }
    }
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    supportShowModal(item) {
      this.suggestionId = item;
      this.isSuggestionSupport = true;
    },
    clickOutSide(e) {
      let el = this.$refs.modalContent;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.close();
      }
    },
  },
};
</script>

<style></style>
