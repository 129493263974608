<template>
  <main class="col-12 col-lg-8 px-0 px-sm-3 pr-xl-0">
    <SilderCard v-if="getSliderPicture && getSliderPicture.length > 0" :getSliderPicture="getSliderPicture"/>
    <b-tabs
      content-class="mt-3"
      :active-nav-item-class="activeTab"
      v-model="selectActiveTab"
    >
      <b-tab title="Tüm Fikirler" active>
        <NewestsCard @edit-suggestion="editSuggestion" />
      </b-tab>
      <b-tab title="Beğenilenler">
        <LikedCard @edit-suggestion="editSuggestion" />
      </b-tab>
      <!-- <b-tab title="Proje Fikirleri">
        <ProductideaCard @edit-suggestion-rd="editSuggestionRD" />
      </b-tab> -->
    </b-tabs>
    <transition name="fade">
      <ShareIdeas
        v-if="isShareIdea"
        @close="isShareIdea = $event"
        :editNewestsData="editNewestsData"
        isIdea="main"
      />
    </transition>
    <transition name="fade">
      <AutomotiveProduct
        v-if="isShareProduct"
        @close="isShareProduct = $event"
        :editPagedData="editPagedData"
        isIdea="main"
      />
    </transition>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { upload } from "../../services";
import { sleep, lowerCaseKeys, fileTypeCheck } from "../../helpers";
import NewestsCard from "./Cards/NewestsCard.vue";
import LikedCard from "./Cards/LikedCard.vue";
//import ProductideaCard from "./Cards/ProductideaCard.vue";
import ShareIdeas from "../Modals/ShareIdeas.vue";
import AutomotiveProduct from "../Modals/AutomotiveProduct.vue";
import SilderCard from "./Cards/SilderCard.vue";
export default {
  name: "HomeMain",
  data() {
    return {
      activeTab: "",
      selectActiveTab: 0,
      isShareIdea: false,
      editNewestsData: null,
      editPagedData: null,
      isShareProduct: false,
    };
  },
  components: {
    NewestsCard,
    LikedCard,
    //ProductideaCard,
    ShareIdeas,
    AutomotiveProduct,
    SilderCard,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    pageData() {
      return this.$store.getters["suggestionRD/pageData"];
    },
    mostLikedData() {
      return this.$store.getters["mostLiked/pageData"];
    },
    getSliderPicture() {
      return this.$store.getters["suggestion/getSliderPicture"];
    },
  },
  watch: {
    selectActiveTab() {
      if (this.selectActiveTab == 0) {
        this.activeTab = "active-newests";
      } else if (this.selectActiveTab == 1) {
        if (this.mostLikedData.length == 0) {
          this.$store.dispatch("mostLiked/setData");
        }
        this.activeTab = "active-liked";
      // } else if (this.selectActiveTab == 2) {
      //   this.activeTab = "active-product-ideas";
      //   if (this.pageData.length == 0) {
      //     this.$store.dispatch("suggestionRD/setData");
      //   }
      } else {
        this.activeTab = "active-product-ideas";
        if (this.pageData.length == 0) {
          this.$store.dispatch("suggestionRD/setData");
        }
      }
    },
    isShareIdea() {
      if (!this.isShareIdea) this.editNewestsData = null;
    },
  },
  methods: {
    editSuggestion(event) {
      this.editNewestsData = event;
      this.editNewestsData.DownloadFile = [];
      if (this.editNewestsData.FileIds.length > 0) {
        this.editNewestsData.FileIds.forEach(async (element) => {
          const res = await upload.fetchDownloadFile(element);
          this.editNewestsData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
            fileType: fileTypeCheck(res.Data.Extension),
          });
        });
      }
      this.isShareIdea = true;
    },
    async editSuggestionRD(event) {
      this.editPagedData = event;
      this.editPagedData.DownloadFile = [];
      this.$store.dispatch("loading/setIsLoading", true);
      if (this.editPagedData.FileIds.length > 0) {
        this.editPagedData.FileIds.forEach(async (element) => {
          const res = await upload.fetchDownloadFile(element);
          this.editPagedData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
            fileType: fileTypeCheck(res.Data.Extension),
          });
        });
      }
      if (this.user.Data.ProjectDocumentId != null) {
        const res = await upload.fetchDownloadFile(
          this.user.Data.ProjectDocumentId
        );
        this.editPagedData.ProjeDocument = {
          DownloadBinary: res.Data.DownloadBinary,
          Extension: res.Data.Extension,
          Filename: res.Data.Filename,
          Id: res.Data.Id,
        };
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isShareProduct = true;
    },
  },
  async mounted() {
    const routeProductId = lowerCaseKeys(this.$route.query);
    if (routeProductId.productid) {
      await sleep(500);
      this.selectActiveTab = 2;
    }
    if (!this.getSliderPicture) this.$store.dispatch("suggestion/getSliderPicture");
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/scss/home-main.scss";
</style>
