<template>
  <div id="modalDetail">
    <div
      id="modal-idea-detail"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5)"
      @click="close"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" ref="modalContent" @click.stop>
          <div class="modal-header bg-orange rounded-0">
            <h5 class="modal-title text-center text-white mx-auto">
              FİKİR DETAYI
            </h5>
            <button
              @click="close"
              type="button"
              class="close"
              id="btnDetailClose"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="container mb-3 mw-100">
            <div class="row pl-3 pl-sm-5 mb-2 modal-bar">Fikir Bilgileri</div>
            <dl class="row px-3 px-sm-5 pt-3 mb-0">
              <dt>Fikir No</dt>
              <dd>{{ detailData.SuggestionNumber }}</dd>
              <dt>Şirket</dt>
              <dd>{{ detailData.CompanyName }}</dd>
              <dt>Fikri Yapan</dt>
              <dd class="text-uppercase">
                {{ detailData.CreatedCustomerName }} (
                {{ detailData.CreatedCustomerCompanyName }} )
              </dd>
              <dt>Bölge</dt>
              <dd>{{ detailData.OfficeLocation }}</dd>

              <dt>Fikir Tarihi</dt>
              <dd>{{ detailData.SuggestionDate | newDateFormatterFilter }}</dd>
              
              <dt>Departman</dt>
              <dd>{{ detailData.Department }}</dd>
              <dt>Görev</dt>
              <dd>{{ detailData.Section }}</dd>
              <dt>Statü</dt>
              <dd>
                {{ detailData.SuggestionStatusName }}
              </dd>

              <dt>Değer Akımı</dt>
              <dd class="single">{{ detailData.ValueCurrentName }}</dd>

              <dt>Sorun / Fırsat</dt>
              <dd class="single">{{ detailData.Problem }}</dd>
              <dt>Fikir</dt>
              <dd class="single">{{ detailData.SuggestionDetail }}</dd>
              <dt>Beklenen Fayda</dt>
              <dd class="single">{{ detailData.ExpectedBenefit }}</dd>
              <dt
                v-if="
                  detailData && detailData.SuggestionStatusId == 8 //hayata geçmiş fikir
                "
              >
                Hayata Geçiş Tarihi
              </dt>
              <dd
                v-if="
                  detailData && detailData.SuggestionStatusId == 8 //hayata geçmiş fikir
                "
              >
                {{ detailData.ProjectEndDate | newDateFormatterFilter}}
              </dd>

              <dt
                v-if="
                  detailData && detailData.SuggestionStatusId == 10 //iptal edilmiş fikir
                "
              >
                İptal Nedeni
              </dt>
              <dd
                v-if="
                  detailData && detailData.SuggestionStatusId == 10 //iptal edilmiş fikir
                "
              >
                {{ detailData.ProjectCancelledReason}}
              </dd>

              <dt
                v-if="
                  detailData && detailData.SuggestionStatusId == 4 //red edilmiş fikir
                "
              >
                Red Sebebi
              </dt>
              <dd
                v-if="
                  detailData && detailData.SuggestionStatusId == 4 //red edilmiş fikir
                "
              >
                {{ detailData.CancelReasonName}}
              </dd>

              <dt
                v-if="
                  detailData && detailData.SuggestionStatusId == 4 //red edilmiş fikir
                "
              >
                Red Açıklaması
              </dt>
              <dd
                v-if="
                  detailData && detailData.SuggestionStatusId == 4 //red edilmiş fikir
                "
              >
                {{ detailData.CancelDescription}}
              </dd>                            

              <dt>Doküman</dt>
              <dd class="single">
                <div
                  class="form-group custom-file icon-folder-alt"
                  v-if="detailData && detailData.DownloadFile.length > 0"
                >
                  <p
                    class="form-control d-flex align-items-center cursor-default"
                    v-for="item in detailData.DownloadFile"
                    :key="item.Id"
                  >
                    <span
                      class="flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                      >{{ item.Filename }}.{{ item.Extension }}</span
                    >
                    <a
                      :href="
                        'data:' +
                        item.fileType +
                        ';base64,' +
                        item.DownloadBinary
                      "
                      :download="item.Filename + '.' + item.Extension"
                      class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                      title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      target="_blank"
                    >
                    </a>
                  </p>
                </div>
              </dd>

              <dt
                v-if="
                  detailData &&
                  detailData.ProjectPresentationDocumentFiles.length > 0
                "
              >
                Proje Bildirisi
              </dt>
              <dd
                class="single"
                v-if="
                  detailData &&
                  detailData.ProjectPresentationDocumentFiles.length > 0
                "
              >
                <div
                  class="form-group custom-file icon-folder-alt"
                  v-for="item in detailData.ProjectPresentationDocumentFiles"
                  :key="item.Id"
                >
                  <p
                    class="form-control d-flex align-items-center cursor-default"
                  >
                    <span
                      class="flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                      >{{ item.Filename }}.{{ item.Extension }}</span
                    >
                    <a
                      :href="
                        'data:' +
                        item.fileType +
                        ';base64,' +
                        item.DownloadBinary
                      "
                      :download="item.Filename + '.' + item.Extension"
                      class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                      title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                      target="_blank"
                    >
                    </a>
                  </p>
                </div>
              </dd>
              <!-- <dt v-if="detailData.DownloadFileProjectDocument">
                Proje Sunum Dokümanı
              </dt>
              <dd v-if="detailData.DownloadFileProjectDocument" class="single">
                <a
                  v-if="detailData.DownloadFileProjectDocument"
                  :href="
                    'data:' +
                    detailData.DownloadFileProjectDocument.fileType +
                    ';base64,' +
                    detailData.DownloadFileProjectDocument.DownloadBinary
                  "
                  :download="
                    detailData.DownloadFileProjectDocument.Filename +
                    '.' +
                    detailData.DownloadFileProjectDocument.Extension
                  "
                  class="innovation-pdf mr-4"
                  title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                  alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                  target="_blank"
                >
                  {{ detailData.DownloadFileProjectDocument.Filename }}.{{
                    detailData.DownloadFileProjectDocument.Extension
                  }}
                  <span class="fas fa-file-download"></span>
                </a>
              </dd> -->
            </dl>

            <div class="row pl-3 pl-sm-5 mb-2 modal-bar">
              Etkileşim Bilgileri
            </div>
            <div class="row px-3 px-sm-5">
              <a
                @click="isComment = !isComment"
                class="article-comment-show icon-down"
                id="commentCount"
                >{{ detailData.CommentCount }} Yorum</a
              >
              <span class="article-comment-like">
                <i class="fas fa-thumbs-up">{{ detailData.LikeCount }}</i>
              </span>
              <button
                @click.stop="
                  supportShowModal(
                    isSuggestion == 'productidea-card'
                      ? detailData.SuggestionId
                      : detailData.Id
                  )
                "
                class="btn-article-action icon-arrow-right"
                data-toggle="modal"
                data-target="#modal-support"
              >
                <span
                  class="d-none d-sm-inline tooltipper"
                  title="Destek iste tooltip"
                  >Destek İste</span
                >
              </button>

              <article
                v-if="isComment"
                class="article-comment-container"
                type="Suggestion"
                objectid="718d7c1f-7dec-eb11-bacb-000d3ade7034"
              >
                <div id="suggestiondetailcomments">
                  <div
                    id="commentdiv"
                    v-for="comment in detailData.Comments"
                    :key="comment.Id"
                  >
                    <div
                      class="article-comment"
                      id="article-comment-id"
                      style="position: static"
                    >
                      <div class="article-comment-body">
                        <img
                          v-if="comment.UserProfileImage"
                          class="comment-user-img fallback-image"
                          :src="`data:image/png;base64, ${comment.UserProfileImage}`"
                          :alt="comment.UserName | getInitialsFilter"
                        />
                        <i
                          v-else
                          class="comment-user-img fallback-image"
                          :alt="comment.UserName | getInitialsFilter"
                          >{{ comment.UserName | getInitialsFilter }}</i
                        >
                        <span
                          >{{ comment.UserName }} (
                          {{ comment.CreatedCustomerCompanyName }} )</span
                        ><span
                          class="icon-clock"
                          :title="comment.CreatedOnUtc"
                          >{{ comment.CreatedOnUtc | dateDiffFilter }}</span
                        >
                        <p
                          style="
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                          "
                        >
                          {{ comment.CommentText }}
                        </p>
                      </div>
                      <div
                        class="article-comment-container"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="article-reply">
                  <img
                    v-if="user.Data.UserProfileImage"
                    class="comment-user-img fallback-image"
                    :src="`data:image/png;base64, ${user.Data.UserProfileImage}`"
                    :alt="user.Data.UserName | getInitialsFilter"
                  />
                  <i
                    v-else
                    class="comment-user-img fallback-image"
                    :alt="user.Data.FullName | getInitialsFilter"
                    >{{ user.Data.FullName | getInitialsFilter }}</i
                  >
                  <div class="icon-note">
                    <textarea
                      id="make-comment-textarea"
                      class="autofit"
                      placeholder="Yorum Giriniz..."
                      v-model="commentText"
                      style="padding: 7px 20px !important"
                    ></textarea>
                  </div>
                  <div
                    class="text-right pt-3"
                    v-if="commentText && commentText.length > 0"
                  >
                    <button
                      class="btn-reply btn-cancel"
                      @click.stop="commentText = ''"
                    >
                      VAZGEÇ
                    </button>
                    <button
                      class="btn-reply add-comment-button"
                      @click.stop="createComment"
                    >
                      YANITLA
                    </button>
                  </div>
                </div>
              </article>
            </div>

            <div class="row pl-5 mb-2 modal-bar">Fikir Dağıtım Bilgileri</div>
            <dl class="row px-5 pt-3 mb-0">
              <dt>Fikir Dağıtıcısı</dt>
              <dd>
                {{ detailData.SuggestionExecutiveName }} (
                {{ detailData.SuggestionExecutiveCompanyName }} )
              </dd>

              <dt>İşlem Tarihi</dt>
              <dd>
                {{
                  detailData.SuggestionExecutiveProcessDate
                    | newDateFormatterFilter
                }}
              </dd>

              <dt>Fikir Dağıtıcısı Notu</dt>
              <dd class="single">{{ detailData.SuggestionExecutiveNote }}</dd>
            </dl>

            <div
              class="row pl-5 mb-2 modal-bar"
              v-if="detailData.SuggestionEvaluatorId != 0"
            >
              Fikir Değerlendirme Bilgileri
            </div>
            <dl
              class="row px-5 pt-3 mb-0"
              v-if="detailData.SuggestionEvaluatorId != 0"
            >
              <dt>Fikir Değerlendirici</dt>
              <dd>
                {{ detailData.SuggestionEvaluatorName }} (
                {{ detailData.SuggestionEvaluatorCompanyName }} )
              </dd>

              <dt>İşlem Tarihi</dt>
              <dd>
                {{
                  detailData.SuggestionEvaluatorProcessDate
                    | newDateFormatterFilter
                }}
              </dd>

              <dt>Fikir Değerlendirme Açıklaması</dt>
              <dd class="single">{{ detailData.SuggestionEvaluatorNote }}</dd>
            </dl>

            <!-- <div
              class="row pl-5 mb-2 modal-bar"
              v-if="detailData.SuggestionDecisionMakerId != 0"
            >
              Fikir Karar Verici Bilgileri
            </div>
            <dl
              class="row px-5 pt-3 mb-0"
              v-if="detailData.SuggestionDecisionMakerId != 0"
            >
              <dt>Fikir Karar Komitesi</dt>
              <dd>
                {{ detailData.SuggestionDecisionMakerName }} (
                {{ detailData.SuggestionDecisionMakerCompanyName }} )
              </dd>

              <dt>İşlem Tarihi</dt>
              <dd>
                {{
                  detailData.SuggestionDeciderProcessDate
                    | newDateFormatterFilter
                }}
              </dd>

              <dt>Fikir Karar Komitesi Notu</dt>
              <dd class="single">{{ detailData.SuggestionDeciderNote }}</dd>
            </dl> -->
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <SuggestionSupport
        v-if="isSuggestionSupport"
        @close="isSuggestionSupport = $event"
        :suggestionId="suggestionId"
      />
    </transition>
  </div>
</template>

<script>
import { suggestionService } from "../../../../services";
import { mapGetters } from "vuex";
import {
  suggestionStatus,
  dateFormatter,
  getInitials,
  newDateFormatter,
  fileTypeCheck,
  dateDiff,
} from "../../../../helpers";
import SuggestionSupport from "./SuggestionSupport.vue";
export default {
  name: "CardDetail",
  data() {
    return {
      isComment: false,
      commentText: "",
      suggestionId: null,
      isSuggestionSupport: false,
    };
  },
  components: {
    SuggestionSupport,
  },
  filters: {
    suggestionStatusFilter(value) {
      return suggestionStatus(value);
    },
    dateFormatterFilter(value) {
      return dateFormatter(value);
    },
    newDateFormatterFilter(value) {
      return newDateFormatter(value);
    },
    getInitialsFilter(value) {
      return getInitials(value);
    },
    dateDiffFilter(value) {
      return dateDiff(value);
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  props: ["detailData", "isSuggestion"],
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    async createComment() {
      const id =
        this.isSuggestion == "productidea-card"
          ? this.detailData.SuggestionId
          : this.detailData.Id;
      const body = {
        CommentText: this.commentText,
        SuggestionId: id,
        UserId: this.user.Data.UserId,
      };
      try {
        const res = await suggestionService.fetchCreateComment(body);
        if (res.Data) {
          this.commentText = null;
          this.$toast.success("Yorumunuz başarıyla yapılmıştır.", {
            position: "top",
          });
          if (this.isSuggestion == "productidea-card") {
            this.$store.dispatch("suggestionRD/deletePagedData");
            this.$store.dispatch("suggestionRD/setData");
          } else if (this.isSuggestion == "idea") {
            this.$store.dispatch("ideas/deleteData");
            this.$store.dispatch("ideas/setData", {
              pageSize: 15,
              pageIndex: 0,
              suggestionTypes: 1,
              companyId: 1,
            });
          } else if (this.isSuggestion == "my-idea-product") {
            this.$store.dispatch("ideas/deleteData");
            this.$store.dispatch("ideas/setData", {
              pageSize: 15,
              pageIndex: 0,
              suggestionTypes: 3,
              companyId: 1,
            });
          } else {
            this.$store.dispatch("suggestionNewests/deleteNewestsData");
            this.$store.dispatch("suggestionNewests/setData");
          }
          this.close();
        }
      } catch (error) {
        this.$toast.error("Yorumunuz gönderilirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    typeCheck() {
      this.detailData.DownloadFile.fileType = fileTypeCheck(
        this.detailData.DownloadFile.Extension
      );
    },
    supportShowModal(item) {
      this.suggestionId = item;
      this.isSuggestionSupport = true;
    },
  },
  async created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    if (this.detailData.DownloadFile) this.typeCheck();
  },
};
</script>

<style></style>
