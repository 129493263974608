<template>
  <header class="row pt-3 pb-3 pt-md-5 pb-md-5 h-auto">
    <div class="col-12">
      <!-- <h1 class="mb-0">
        <span class="d-none d-sm-inline" style="color: #fff">FİKİR PLATFORMU</span>
      </h1> -->
      <div v-html="user.Data.CompanyConfiguration.HomeBannerText"></div>
      <!-- <p>
        Fikir Platformu Ürün, süreç, iş modeli, her alanda ve sürekli
        inovasyon!
      </p>
      <p>
        "Doğuş Grubu Kurumsal İnovasyon Sistemi" ile önerilerinizi bütünsel ve
        odaklı bir yaklaşımla ele alarak hayata geçiriyoruz. Sen de
        <a class="router-link"  @click="$router.push('/FrequentlyAskedQuestion')">sıkça sorulan soruları</a>
        okuyarak önerini yap; hayata geçirelim.
      </p> -->
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeTitle",
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
};
</script>

<style></style>
