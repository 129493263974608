<template>
  <div
    id="modal-share-invendo"
    class="modal fade show custom-modal"
    tabindex="-1"
    role="dialog"
    style="display: block; background-color: rgba(0, 0, 0, 0.5)"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" ref="modalContent">
        <div class="modal-header bg-orange rounded-0">
          <h5 class="modal-title text-center text-white mx-auto">
            INVENDO GİRİŞİ
          </h5>
          <button
            @click="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <form>
          <div class="modal-body">
            <input
              name="__RequestVerificationToken"
              type="hidden"
              value="YSLdF3E1DDwzlO_2aoxWm5D-eNt49GQnL23j_IaVnQDwuC7NcRQuZ3nDq1k3lsPaAufT_306xjTTQ4tgD5s-fszXsRTN4eBSNyLc7SStgbrRj-i0Q8fjarzl15hHlvPkHvbBOd5t1as1mfqcxhpuWg2"
            />
            <div class="form-group icon-user">
              <input
                type="text"
                class="form-control ui-autocomplete-input"
                id="idea-recipient-invendo-name"
                :value="user.Data.FullName"
                disabled="'disabled'"
                autocomplete="off"
              />
              <input
                type="hidden"
                id="idea-recipient-invendo-userId"
                value="f243b899-0fb6-4ce7-9611-f7c362ec4cdc"
              />
            </div>

            <div class="form-group">
              <label>1-) İş fikrinizi bir cümle ile açıklar mısınız?</label>
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoProblem"
                name="Problem"
                placeholder=""
                required="required"
                rows="2"
                validationelement="1"
                v-model="problem"
              ></textarea>
            </div>
            <div
              id="txtInvendoProblemError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('problem') ? { display: 'block' } : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <label
                >2-) Çözmeye çalıştığınız ihtiyaç veya problemi tanımlar
                mısınız?</label
              >
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoSuggestionDetail"
                name="SuggestionDetail"
                placeholder=""
                required="required"
                rows="2"
                validationelement="1"
                v-model="suggestionDetail"
              ></textarea>
            </div>
            <div
              id="txtInvendoSuggestionDetailError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('suggestionDetail')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <label>3-) Hedef kitleniz kim ve ne kadar büyüklükte?</label>
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoTargetGroup"
                name="TargetGroup"
                placeholder=""
                rows="2"
                v-model="targetGroup"
              ></textarea>
            </div>
            <div class="form-group">
              <label
                >4-) Problemi nasıl çözmeyi planlıyorsunuz? (İş modeliniz
                nedir?)</label
              >
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoHowToResolveProblem"
                name="HowToResolveProblem"
                placeholder=""
                rows="2"
                v-model="howToResolveProblem"
              ></textarea>
            </div>
            <div class="form-group">
              <label>5-) Nasıl para kazanacaksınız / kazandıracaksınız?</label>
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoExpectedBenefit"
                name="ExpectedBenefit"
                placeholder=""
                rows="2"
                validationelement="1"
                v-model="expectedBenefit"
              ></textarea>
            </div>
            <div
              id="txtInvendoExpectedBenefitError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('expectedBenefit')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <label
                >6-) Benzer uygulama (yurtiçinde veya dışında) var mı?</label
              >
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoHasSimilarApp"
                name="HasSimilarApp"
                placeholder=""
                rows="2"
                v-model="hasSimilarApp"
              ></textarea>
            </div>
            <div class="form-group">
              <label
                >7-) Fikrinizin benzerliklerini ve farklılıklarını açıklayabilir
                misiniz? Doğuş Grubu bu işi neden yapmalı?</label
              >
              <textarea
                class="form-control short"
                cols="20"
                id="txtInvendoIdeaSimilarityDiffrences"
                name="IdeaSimilarityDiffrences"
                placeholder=""
                rows="2"
                v-model="ideaSimilarityDiffrences"
              ></textarea>
              <input
                data-val="true"
                data-val-required="The Id field is required."
                id="hdnInvendoAccountId"
                name="Account.Id"
                style="display: none"
                type="text"
                value=""
              />
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button
              v-if="!tmpEditData && isDraft"
              type="button"
              id="btnSendSuggestion"
              class="btn-innovation-modal bg-info"
              :disabled="isBtnDisabled"
              @click="saveDraft"
            >
              <span class="mr-2">Taslak Olarak Kaydet</span>
              <b-icon icon="bookmark"></b-icon>
            </button>
            <button
              v-if="tmpEditData && isDraft"
              type="button"
              id="btnSendSuggestion"
              class="btn-innovation-modal bg-info"
              :disabled="isBtnDisabled"
              @click="editDraft"
            >
              <span class="mr-2">Taslağı Düzenle</span>
              <b-icon icon="bookmark"></b-icon>
            </button>
            <button
              v-if="tmpEditData && !isDraft"
              @click="editInvendo"
              type="button"
              id="btnSendSuggestion"
              class="btn-innovation-modal bg-orange"
              :disabled="isBtnDisabled"
            >
              DÜZENLE <span class="icon-arrow-right pl-2"></span>
            </button>
            <button
              v-else-if="tmpEditData && isDraft"
              @click="editInvendo"
              type="button"
              id="btnSendSuggestion"
              class="btn-innovation-modal bg-orange"
              :disabled="isBtnDisabled"
            >
              GÖNDER <span class="icon-arrow-right pl-2"></span>
            </button>
            <button
              v-else
              type="button"
              @click="createInvendo"
              id="btnSendSuggestion"
              class="btn-innovation-modal bg-orange"
            >
              GÖNDER <span class="icon-arrow-right pl-2"></span>
            </button>
          </div>
          <input
            id="hdnInvendoSuggestionType"
            name="SuggestionType"
            style="display: none"
            type="text"
            value="Invendo"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { suggestionInvendoService } from "../../services";
import { isNil } from "../../helpers";
export default {
  name: "InvendoIdeas",
  data() {
    return {
      requiredField: [],
      problem: "",
      suggestionDetail: "",
      targetGroup: "",
      howToResolveProblem: "",
      expectedBenefit: "",
      hasSimilarApp: "",
      ideaSimilarityDiffrences: "",
      isBtnDisabled: false,
      tmpEditData: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    this.tmpEditData = this.editData ? { ...this.editData } : null;

    if (this.tmpEditData) {
      this.problem = this.tmpEditData.Problem;
      this.suggestionDetail = this.tmpEditData.SuggestionDetail;
      this.targetGroup = this.tmpEditData.TargetGroup;
      this.howToResolveProblem = this.tmpEditData.HowToResolveProblem;
      this.expectedBenefit = this.tmpEditData.ExpectedBenefit;
      this.hasSimilarApp = this.tmpEditData.HasSimilarApp;
      this.ideaSimilarityDiffrences = this.tmpEditData.IdeaSimilarityDiffrences;
    }
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
  props: {
    editData: {
      type: Object,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    clickOutSide(e) {
      let el = this.$refs.modalContent;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.close();
      }
    },
    isRequiredFieldCheck() {
      if (
        !isNil(this.problem) &&
        !isNil(this.suggestionDetail) &&
        !isNil(this.expectedBenefit)
      ) {
        return true;
      } else {
        return false;
      }
    },
    requiredFieldFill() {
      this.requiredField = [];
      if (this.problem == "") this.requiredField.push("problem");
      if (this.suggestionDetail == "")
        this.requiredField.push("suggestionDetail");
      if (this.expectedBenefit == "")
        this.requiredField.push("expectedBenefit");
    },
    async createInvendo() {
      if (this.isRequiredFieldCheck()) {
        const body = {
          SuggestionTypeId: 4,
          CreatedCustomerId: this.user.Data.UserId,
          CompanyId: this.user.Data.CompanyId,
          GroupCompanyId: this.user.Data.GroupCompanyId,
          Problem: this.problem,
          SuggestionDetail: this.suggestionDetail,
          TargetGroup: this.targetGroup,
          HowToResolveProblem: this.howToResolveProblem,
          ExpectedBenefit: this.expectedBenefit,
          HasSimilarApp: this.hasSimilarApp,
          IdeaSimilarityDiffrences: this.ideaSimilarityDiffrences,
        };
        try {
          await suggestionInvendoService.fetchCreateSuggestionInvendo(body);
          this.$toast.success(
            "Öneri invendo'nuz başarıyla oluşturuldu.",
            {
              position: "top",
            }
          );
          this.$store.dispatch("suggestionNewests/deleteNewestsData");
          this.$store.dispatch("suggestionNewests/setData");
          this.$store.dispatch("suggestionRD/deletePagedData");
          this.$store.dispatch("suggestionRD/setData");
          this.close();
        } catch (error) {
          this.$toast.error(
            "Öneri invendo'nuz oluşturulurken bir hata oluştu.",
            {
              position: "top",
            }
          );
        }
      } else {
        this.requiredFieldFill();
      }
    },
    async saveDraft() {
      const body = {
        SuggestionTypeId: 4,
        CreatedCustomerId: this.user.Data.UserId,
        CompanyId: this.user.Data.CompanyId,
        GroupCompanyId: this.user.Data.GroupCompanyId,
        Problem: this.problem,
        SuggestionDetail: this.suggestionDetail,
        TargetGroup: this.targetGroup,
        HowToResolveProblem: this.howToResolveProblem,
        ExpectedBenefit: this.expectedBenefit,
        HasSimilarApp: this.hasSimilarApp,
        IdeaSimilarityDiffrences: this.ideaSimilarityDiffrences,
        SuggestionStatusId: 11,
      };
      try {
        await suggestionInvendoService.fetchCreateSuggestionInvendo(body);
        this.$toast.success(
          "Öneri invendo'nuz başarıyla oluşturuldu.",
          {
            position: "top",
          }
        );
        this.$store.dispatch("suggestionNewests/deleteNewestsData");
        this.$store.dispatch("suggestionNewests/setData");
        this.$store.dispatch("suggestionRD/deletePagedData");
        this.$store.dispatch("suggestionRD/setData");
        this.close();
      } catch (error) {
        this.$toast.error(
          "Öneri invendo'nuz oluşturulurken bir hata oluştu.",
          {
            position: "top",
          }
        );
      }
    },
    async editDraft() {
      this.tmpEditData.Problem = this.problem;
      this.tmpEditData.SuggestionDetail = this.suggestionDetail;
      this.tmpEditData.TargetGroup = this.targetGroup;
      this.tmpEditData.HowToResolveProblem = this.howToResolveProblem;
      this.tmpEditData.ExpectedBenefit = this.expectedBenefit;
      this.tmpEditData.HasSimilarApp = this.hasSimilarApp;
      this.tmpEditData.IdeaSimilarityDiffrences = this.ideaSimilarityDiffrences;
      try {
        await suggestionInvendoService.fetchUpdateSuggestionInvendo(
          this.tmpEditData
        );
        this.$toast.success(
          "Öneri invendo'nuz başarıyla oluşturuldu.",
          {
            position: "top",
          }
        );
        this.$store.dispatch("draft/deleteDraftInvendoData");
        const body = {
          pageIndex: 0,
          pageSize: 15,
          suggestionStatuses: [11],
          createdUserId: this.user.Data.UserId,
          loginUserId: this.user.Data.UserId,
        };
        this.$store.dispatch("draft/setDraftInvendoData", body);
        this.close();
      } catch (error) {
        this.$toast.error(
          "Öneri invendo'nuz oluşturulurken bir hata oluştu.",
          {
            position: "top",
          }
        );
      }
    },
    async editInvendo() {
      this.tmpEditData.Problem = this.problem;
      this.tmpEditData.SuggestionDetail = this.suggestionDetail;
      this.tmpEditData.TargetGroup = this.targetGroup;
      this.tmpEditData.HowToResolveProblem = this.howToResolveProblem;
      this.tmpEditData.ExpectedBenefit = this.expectedBenefit;
      this.tmpEditData.HasSimilarApp = this.hasSimilarApp;
      this.tmpEditData.IdeaSimilarityDiffrences = this.ideaSimilarityDiffrences;
      this.isDraft ? (this.tmpEditData.SuggestionStatusId = 1) : "";

      if (this.isRequiredFieldCheck()) {
        this.isBtnDisabled = true;
        try {
          await suggestionInvendoService.fetchUpdateSuggestionInvendo(
            this.tmpEditData
          );
          this.$toast.success("Invendo Fikriniz başarıyla oluşturuldu. Ana sayfadan görüntüleyebilirsiniz", {
            position: "top",
          });
          this.tmpEditData = null;
          this.close();
          this.$store.dispatch("suggestionNewests/deleteNewestsData");
          this.$store.dispatch("suggestionNewests/setData");
          this.$store.dispatch("suggestionRD/deletePagedData");
          this.$store.dispatch("suggestionRD/setData");
          if (this.isDraft) {
            this.$store.dispatch("draft/deleteDraftInvendoData");
            const body = {
              pageIndex: 0,
              pageSize: 15,
              suggestionStatuses: [11],
              createdUserId: this.user.Data.UserId,
              loginUserId: this.user.Data.UserId,
            };
            this.$store.dispatch("draft/setDraftInvendoData", body);
          }
        } catch (error) {
          this.$toast.error("Güncelleştirme başarısız.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        this.requiredFieldFill();
      }
    },
  },
};
</script>

<style></style>
