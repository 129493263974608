<template>
  <main-layout>
    <div class="home-container" >
      <content class="container full d-block pb-5">
        <HomeTitle />
        <div class="row">
          <HomeCards />
          <HomeMain />
        </div>
      </content>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../layouts/MainLayout.vue";
import HomeTitle from "../../components/Main/HomeTitle.vue";
import HomeCards from "../../components/Main/HomeCards.vue";
import HomeMain from "../../components/Main/HomeMain.vue";

export default {
  name: "HomePage",
  components: { MainLayout, HomeTitle, HomeCards, HomeMain },
};
</script>

<style>
</style>