<template>
  <article
    class="pt-3 px-3 pt-sm-3 px-sm-5 custom-card is-card-loading"
    type="Suggestion"
  >
    <div class="d-flex flex-nowrap">
      <i class="article-user-img fallback-image flex-shrink-0" alt="md"></i>
      <div
        class="
          article-user-info
          flex-grow-1
          pl-2 pl-sm-3
          pt-sm-2
          align-self-center align-self-sm-auto
        "
      >
        <span class="mb-1 custom-card-name"></span>
        <span class="pt-0 custom-card-subname"></span>
      </div>
    </div>

    <div class="article-img clearfix"></div>
    <h2 class="article-header"></h2>

    <p class="article-content"></p>

    <div
      class="
        article-response
        d-flex
        justify-content-end
        align-items-center
        flex-wrap
      "
    >
      <span class="custom-card-like"></span>
      <span class="custom-card-comment"></span>
      <span class="custom-card-statu"></span>
    </div>

    <div
      class="
        article-actions
        py-1
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <div class="custom-card-time"></div>
      <div class="fake-btn-group">
        <div
          class="fake-btn custom-card-create-comment d-none d-sm-inline mr-2"
        ></div>
        <div
          class="fake-btn custom-card-create-like d-none d-sm-inline mr-2"
        ></div>
        <div
          class="
            fake-btn
            custom-card-create-support
            d-none d-sm-inline
            tooltipper
          "
        ></div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "LoadingCard",
};
</script>

<style lang="scss">
@import "../../../assets/styles/scss/loading-card.scss";
</style>
