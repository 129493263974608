<template>
  <div id="divShare">
    <div
      id="modal-edit-comment"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" ref="modalContent">
          <div class="modal-header bg-orange rounded-0">
            <h5 class="modal-title text-center text-white mx-auto">YORUM</h5>
            <button type="button" class="close" data-dismiss="modal" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form>
            <div class="modal-body" id="comment-edit-form">
              <input
                name="__RequestVerificationToken"
                type="hidden"
                value="ZV_cpY9vKaklFZRjvsLAhjAZdVP39I3cvRigPX-YWPe2t7mFcYjB2MXQQGgoE2c5gS-QQrYLeugIIqOKnUs1-bBs9TtcXuK8QxNstNIdMFBizigSlTNrUcoD3p7Z4yDuG3nfRXclq14ynZ9_KuROIg2"
              />
              <div class="form-group icon-user">
                <input
                  type="text"
                  class="form-control"
                  id="idea-recipient-arge-name"
                  :value="commentEditData.UserName"
                  disabled="'disabled'"
                />
                <input
                  type="hidden"
                  id="idea-recipient-arge-userId"
                  value="f243b899-0fb6-4ce7-9611-f7c362ec4cdc"
                />
              </div>
              <div class="form-group">
                <textarea
                  v-model="commentText"
                  class="form-control short"
                  cols="20"
                  id="txtCommentText"
                  name="CommentText"
                  placeholder="Yorum..."
                  required="required"
                  rows="2"
                  validationelement="1"
                >
deneme</textarea
                >
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center">
              <button
                type="button"
                @click="editComment"
                class="btn-innovation-modal bg-orange"
              >
                GÜNCELLE<span class="icon-arrow-right pl-2"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentEdit",
  data() {
    return {
      commentText: "",
    };
  },
  props: ["commentEditData"],
  created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    this.commentText = this.commentEditData.CommentText;
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
  destroyed() {
    const $body = document.querySelector("body");
    $body.style.removeProperty("overflow");
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    clickOutSide(e) {
      let el = this.$refs.modalContent;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.close();
      }
    },
    async editComment() {
      this.$emit("edit-comment", this.commentText);
      this.commentText = null;
    },
  },
};
</script>

<style></style>
