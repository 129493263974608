<template>
  <section id="most-liked" class="tab-pane">
    <template v-if="mostLikedData.length == 0">
      <LoadingCard v-for="item in 5" :key="item" />
    </template>
    <template v-else>
      <article
        v-for="(item, key) in mostLikedData"
        :key="key"
        class="pt-3 px-3 pt-sm-3 px-sm-5"
        type="Suggestion"
        objectid="32db320f-0962-eb11-a812-000d3adbe61b"
      >
        <div class="d-flex flex-nowrap">
          <img
            v-if="item.UserProfileImage"
            class="article-user-img fallback-image"
            :src="`data:image/png;base64, ${item.UserProfileImage}`"
            :alt="item.CreatedCustomerName | getInitialsFilter"
          />
          <i
            v-else
            class="article-user-img fallback-image flex-shrink-0"
            :alt="item.CreatedCustomerName | getInitialsFilter"
            >{{ item.CreatedCustomerName | getInitialsFilter }}</i
          >
          <div
            class="article-user-info flex-grow-1 pl-2 pl-sm-3 pt-sm-2 align-self-center align-self-sm-auto"
          >
            <span class="mb-1"
              >{{ item.CreatedCustomerName }} ( {{ item.CompanyName }} )</span
            >
            <span class="pt-0"
              >{{ item.CreatedCustomerCompanyName }} şirketinden paylaşılan
              fikir</span
            >
          </div>
          <div
            class="article-brand ml-1 ml-sm-2 ml-md-3"
            :style="[
              item.SuggestionStatusId != '1' ? { marginRight: '4rem' } : '',
            ]"
          >
            <span class="smaller"><span>#</span>{{ key + 1 }}</span>
            <svg viewBox="0 0 44.49 61.72">
              <path
                class="cls-1"
                d="M40.26,35.39A15.68,15.68,0,1,0,55.94,51.06,15.69,15.69,0,0,0,40.26,35.39Z"
                transform="translate(-18.02 -28.82)"
              ></path>
              <polygon
                class="cls-1"
                points="14.29 22.5 16.16 22.5 16.41 20.46 14.55 20.46 14.29 22.5"
              ></polygon>
              <path
                class="cls-1"
                d="M61.28,52.3a3,3,0,0,1,0-2.47l1-2a1.8,1.8,0,0,0-.42-2.13l-1.76-1.49a3.06,3.06,0,0,1-.95-2.28l.2-2.3a1.83,1.83,0,0,0-1.21-1.8L56,37.08a3,3,0,0,1-1.75-1.75l-.7-2.19a1.85,1.85,0,0,0-1.68-1.22h-.13l-2.29.19h-.16a3.08,3.08,0,0,1-2.12-1l-1.49-1.76a1.82,1.82,0,0,0-1.37-.6,1.63,1.63,0,0,0-.76.17l-2,1.06a3.06,3.06,0,0,1-2.47,0L37,29a1.63,1.63,0,0,0-.76-.17,1.8,1.8,0,0,0-1.37.6l-1.49,1.76a3.08,3.08,0,0,1-2.12,1h-.16l-2.3-.19h-.12A1.85,1.85,0,0,0,27,33.14l-.7,2.19a3,3,0,0,1-1.75,1.75l-2.19.7a1.83,1.83,0,0,0-1.21,1.8l.19,2.3a3,3,0,0,1-.94,2.28l-1.76,1.49a1.82,1.82,0,0,0-.43,2.13l1.06,2a3,3,0,0,1,0,2.47l-1.06,2.05a1.82,1.82,0,0,0,.43,2.13L20.38,58a3,3,0,0,1,.94,2.29l-.19,2.29a1.85,1.85,0,0,0,1.21,1.81l2.19.7a3,3,0,0,1,1.75,1.74l.09.3L27,69a1.84,1.84,0,0,0,1.68,1.21h.13l2.29-.2h.16a3,3,0,0,1,2.12,1l1.49,1.76a1.8,1.8,0,0,0,1.37.6,1.64,1.64,0,0,0,.76-.18L39,72.07a3.06,3.06,0,0,1,2.47,0l2,1.06a1.64,1.64,0,0,0,.76.18,1.82,1.82,0,0,0,1.37-.6L47.17,71a3,3,0,0,1,2.12-1h.16l2.29.2h.13A1.84,1.84,0,0,0,53.55,69l.6-1.9.1-.3A3,3,0,0,1,56,65.05l2.2-.7a1.85,1.85,0,0,0,1.21-1.81l-.2-2.29A3,3,0,0,1,60.15,58l1.76-1.48a1.84,1.84,0,0,0,.42-2.13Zm-21,15.45A16.69,16.69,0,1,1,57,51.06,16.7,16.7,0,0,1,40.26,67.75Z"
                transform="translate(-18.02 -28.82)"
              ></path>
              <path
                class="cls-1"
                d="M54.75,68.56l-.24.74a2.81,2.81,0,0,1-2.64,1.91h-.21L49.36,71h-.07a2.11,2.11,0,0,0-1.35.6l-1.49,1.76a2.8,2.8,0,0,1-2.14,1A2.69,2.69,0,0,1,43.08,74L41,73a1.85,1.85,0,0,0-.77-.15,1.88,1.88,0,0,0-.77.15L37.44,74a2.61,2.61,0,0,1-1.22.29,2.8,2.8,0,0,1-2.14-1L32.59,71.6a2.11,2.11,0,0,0-1.35-.6h-.07l-2.3.19h-.21A2.84,2.84,0,0,1,26,69.3l-.23-.74L19.3,84.06c-.21.52.06.82.6.68l7.82-2.07a1.43,1.43,0,0,1,1.5.6L33.4,90.2c.29.47.7.44.9-.08l6-14.22,6,14.22c.21.52.61.55.9.08l4.18-6.93a1.43,1.43,0,0,1,1.5-.6l7.82,2.07c.54.14.81-.16.6-.68Z"
                transform="translate(-18.02 -28.82)"
              ></path>
            </svg>
          </div>
          <div
            class="article-further-menu dropdown ml-1 ml-sm-2 ml-md-3 ml-lg-4 show"
            v-if="
              user.Data.UserId === item.CreatedCustomerId &&
              item.SuggestionStatusId == '1'
            "
          >
            <a
              class="btn btn-outline-light"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              @click.stop="cardDropdownFn(item.Id)"
            >
              <svg width="15px" height="3px">
                <path
                  fill-rule="evenodd"
                  opacity="0.902"
                  fill="rgb(53, 59, 72)"
                  d="M13.333,3.000 C12.413,3.000 11.666,2.328 11.666,1.500 C11.666,0.672 12.413,-0.000 13.333,-0.000 C14.254,-0.000 15.000,0.672 15.000,1.500 C15.000,2.328 14.254,3.000 13.333,3.000 ZM7.500,3.000 C6.579,3.000 5.833,2.328 5.833,1.500 C5.833,0.672 6.579,-0.000 7.500,-0.000 C8.420,-0.000 9.166,0.672 9.166,1.500 C9.166,2.328 8.420,3.000 7.500,3.000 ZM1.666,3.000 C0.746,3.000 -0.000,2.328 -0.000,1.500 C-0.000,0.672 0.746,-0.000 1.666,-0.000 C2.587,-0.000 3.333,0.672 3.333,1.500 C3.333,2.328 2.587,3.000 1.666,3.000 Z"
                ></path>
              </svg>
            </a>
            <div
              v-if="item.Id === cardDropdownId"
              ref="carDropdown"
              class="dropdown-menu dropdown-menu-right show"
              x-placement="bottom-end"
              style="
                position: absolute;
                transform: translate3d(-121px, 36px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
                cursor: pointer;
              "
            >
              <a class="dropdown-item" @click="deleteCardShowModal(item.Id)"
                >Sil</a
              >
              <a class="dropdown-item" @click.stop="editSuggestion(item)"
                >Düzenle</a
              >
            </div>
          </div>
        </div>

        <div class="article-img clearfix"></div>
        <h2
          class="article-header"
          data-toggle="modal"
          data-target="#modal-idea-detail"
        >
          <svg width="37px" height="30px">
            <path
              fill-rule="evenodd"
              d="M12.476,21.730 C14.795,18.628 15.955,15.122 15.955,11.209 C15.955,7.847 15.103,5.166 13.398,3.166 C11.694,1.166 9.668,0.166 7.321,0.166 C5.309,0.166 3.598,0.906 2.187,2.384 C0.777,3.862 0.071,5.572 0.071,7.514 C0.071,9.368 0.686,10.970 1.915,12.318 C3.145,13.665 4.555,14.339 6.148,14.339 C7.517,14.339 8.648,14.005 9.542,13.339 C9.822,13.136 10.032,13.035 10.171,13.035 C10.311,13.035 10.437,13.122 10.548,13.296 C10.716,13.556 10.800,13.962 10.800,14.513 C10.800,17.063 9.976,19.519 8.327,21.882 C6.679,24.243 4.402,25.975 1.496,27.077 L1.496,29.207 C6.497,27.323 10.157,24.830 12.476,21.730 ZM32.635,21.730 C34.954,18.628 36.113,15.122 36.113,11.209 C36.113,7.847 35.261,5.166 33.557,3.166 C31.853,1.166 29.827,0.166 27.480,0.166 C25.468,0.166 23.757,0.906 22.346,2.384 C20.935,3.862 20.229,5.572 20.229,7.514 C20.229,9.368 20.844,10.970 22.073,12.318 C23.303,13.665 24.714,14.339 26.306,14.339 C27.676,14.339 28.807,14.005 29.701,13.339 C29.981,13.136 30.190,13.035 30.330,13.035 C30.470,13.035 30.595,13.122 30.707,13.296 C30.874,13.556 30.958,13.962 30.958,14.513 C30.958,17.063 30.134,19.519 28.486,21.882 C26.837,24.243 24.560,25.975 21.654,27.077 L21.654,29.207 C26.656,27.323 30.316,24.830 32.635,21.730 Z"
            ></path>
          </svg>
          <span @click.stop="cardDetail(item)">{{ item.Problem }}</span>
        </h2>

        <p class="article-content">{{ item.SuggestionDetail }}</p>

        <div
          class="article-response d-flex justify-content-end align-items-center flex-wrap"
        >
          <span
            class="wholiked__wrapper"
            :style="[item.LikeCount > 0 ? { cursor: 'pointer' } : '']"
            @click.stop="whoLikedFn(item.Id)"
            >{{ item.LikeCount }} kişi beğendi
            <transition name="fade">
              <ul
                v-if="whoLikedId == item.Id && item.LikeCount > 0"
                class="wholiked__wrapper--list"
                ref="whoLikedList"
              >
                <li
                  class="wholiked__wrapper--list-item"
                  v-for="like in item.Likes"
                  :key="like.Id"
                >
                  <img
                    v-if="like.UserProfileImage"
                    style="width: 24px; height: 24px"
                    class="article-user-img fallback-image"
                    :src="`data:image/png;base64, ${like.UserProfileImage}`"
                    :alt="like.UserName | getInitialsFilter"
                  />
                  <i
                    v-else
                    class="article-user-img fallback-image flex-shrink-0"
                    style="
                      height: 24px;
                      width: 24px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 10px;
                    "
                    :alt="like.UserName | getInitialsFilter"
                    >{{ like.UserName | getInitialsFilter }}</i
                  >
                  <span style="margin-left: 10px">{{ like.UserName }}</span>
                </li>
              </ul>
            </transition></span
          >
          <span>{{ item.CommentCount }} yorum</span>
          <span
            class="statu-circle"
            :class="[
              {
                'statu-green':
                  item.SuggestionStatusId == '6' ||
                  item.SuggestionStatusId == '8',
              },
              {
                'statu-red':
                  item.SuggestionStatusId == '4' ||
                  item.SuggestionStatusId == '7' ||
                  item.SuggestionStatusId == '10',
              },
              {
                'statu-blue':
                  item.SuggestionStatusId == '1' ||
                  item.SuggestionStatusId == '2' ||
                  item.SuggestionStatusId == '3' ||
                  item.SuggestionStatusId == '5' ||
                  item.SuggestionStatusId == '9',
              },
            ]"
            >{{ item.SuggestionStatusName }}</span
          >
        </div>

        <div class="article-actions py-1">
          <span class="icon-clock" :title="item.SuggestionDate" tooltip="">{{
            item.SuggestionDate | dateDiffFilter
          }}</span>
          <button
            @click="isCommentFn(key)"
            :aria-pressed="[key !== commentId ? false : true]"
            class="btn-article-action make-comment icon-bubbles btn-toggle"
          >
            <span class="d-none d-sm-inline">Yorum Yap</span>
          </button>
          <input
            :id="item.Id"
            type="checkbox"
            class="give-heart"
            :checked="item.IsLiked"
          />
          <label
            :for="item.Id"
            class="btn-article-action btn-article-action-label"
            @click="liked(item)"
            ><i class="fas fa-heart"></i><i class="far fa-heart"></i
            ><span class="d-none d-sm-inline">Beğen</span></label
          >
          <button
            @click.stop="supportShowModal(item.Id)"
            class="btn-article-action icon-arrow-right"
            data-toggle="modal"
            data-target="#modal-support"
          >
            <span
              class="d-none d-sm-inline tooltipper"
              title="Destek iste tooltip"
              >Destek İste</span
            >
          </button>
          <button
            style="display: none"
            class="btn-article-action icon-rocket"
            title="Sponsor Ol"
            data-toggle="modal"
            data-target="#modal-sponsor"
            onclick="OpenSponsorModal('b2877315-78da-eb11-bacb-000d3addce7f')"
          >
            <span
              class="d-none d-sm-inline tooltipper"
              title="Sponsor Ol tooltip"
              >Sponsor Ol</span
            >
          </button>
        </div>

        <transition name="slide-down">
          <div v-if="key === commentId" class="article-reply">
            <img
              v-if="user.Data.UserProfileImage"
              class="comment-user-img fallback-image"
              :src="`data:image/png;base64, ${user.Data.UserProfileImage}`"
              :alt="user.Data.FullName | getInitialsFilter"
            />
            <i
              v-else
              class="comment-user-img fallback-image"
              :alt="user.Data.FullName | getInitialsFilter"
              >{{ user.Data.FullName | getInitialsFilter }}</i
            >
            <div class="icon-note">
              <textarea
                v-model="commentText"
                class="autofit"
                placeholder="Yorum Giriniz..."
              ></textarea>
            </div>
            <div
              v-if="commentText && commentText.length > 0"
              class="text-right pt-3"
            >
              <button class="btn-reply btn-cancel" @click="commentText = ''">
                VAZGEÇ
              </button>
              <button
                class="btn-reply add-comment-button"
                @click="createComment(item)"
              >
                YANITLA
              </button>
            </div>
          </div>
        </transition>
        <a
          v-if="item.CommentCount != 0"
          class="article-comment-show icon-down"
          @click="commentShowFn(item.Id)"
          >{{ item.CommentCount }} Yorum</a
        >
        <transition name="slide-down">
          <div
            v-if="isCommentShowId == item.Id"
            class="article-comment-container"
            style=""
          >
            <div
              id="commentdiv"
              v-for="comment in item.Comments"
              :key="comment.Id"
            >
              <div
                v-show="comment.UserId == user.Data.UserId"
                class="article-further-menu dropdown show"
                style="right: 10px; margin-top: 10px; position: absolute"
              >
                <a
                  class="btn btn-outline-light"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click.stop="commentEditFn(comment.Id)"
                >
                  <svg width="15px" height="3px">
                    <path
                      fill-rule="evenodd"
                      opacity="0.902"
                      fill="rgb(53, 59, 72)"
                      d="M13.333,3.000 C12.413,3.000 11.666,2.328 11.666,1.500 C11.666,0.672 12.413,-0.000 13.333,-0.000 C14.254,-0.000 15.000,0.672 15.000,1.500 C15.000,2.328 14.254,3.000 13.333,3.000 ZM7.500,3.000 C6.579,3.000 5.833,2.328 5.833,1.500 C5.833,0.672 6.579,-0.000 7.500,-0.000 C8.420,-0.000 9.166,0.672 9.166,1.500 C9.166,2.328 8.420,3.000 7.500,3.000 ZM1.666,3.000 C0.746,3.000 -0.000,2.328 -0.000,1.500 C-0.000,0.672 0.746,-0.000 1.666,-0.000 C2.587,-0.000 3.333,0.672 3.333,1.500 C3.333,2.328 2.587,3.000 1.666,3.000 Z"
                    ></path>
                  </svg>
                </a>
                <div
                  ref="commentEditPoppup"
                  class="dropdown-menu dropdown-menu-right"
                  :style="[
                    commnetEditId == comment.Id
                      ? { display: 'block', cursor: 'pointer' }
                      : '',
                  ]"
                >
                  <a class="dropdown-item" @click="deleteComment(comment)"
                    >Sil</a
                  >
                  <a
                    class="dropdown-item"
                    @click.stop="commentEditShowModal(comment)"
                    >Düzenle</a
                  >
                </div>
              </div>
              <div
                class="article-comment"
                id="article-comment-id"
                style="position: static"
              >
                <div class="article-comment-body">
                  <img
                    v-if="comment.UserProfileImage"
                    class="comment-user-img fallback-image"
                    :src="`data:image/png;base64, ${comment.UserProfileImage}`"
                    :alt="comment.UserName | getInitialsFilter"
                  />
                  <i
                    v-else
                    class="comment-user-img fallback-image"
                    :alt="comment.UserName | getInitialsFilter"
                    >{{ comment.UserName | getInitialsFilter }}</i
                  >
                  <span
                    >{{ comment.UserName }} ({{
                      comment.UserCompanyName
                    }})</span
                  ><span class="icon-clock" :title="comment.CreatedOnUtc">
                    {{ comment.CreatedOnUtc | dateDiffFilter }}</span
                  >
                  <p
                    style="
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    "
                  >
                    {{ comment.CommentText }}
                  </p>
                </div>
                <div
                  class="article-comment-container"
                  style="display: none"
                ></div>
              </div>
            </div>
          </div>
        </transition>
      </article>
    </template>

    <transition name="fade">
      <SuggestionSupport
        v-if="isSuggestionSupport"
        @close="isSuggestionSupport = $event"
        :suggestionId="suggestionId"
      />
    </transition>
    <transition name="fade">
      <CommentEdit
        v-if="isCommentEdit"
        @close="isCommentEdit = $event"
        @edit-comment="editComment"
        :commentEditData="commentEditData"
      />
    </transition>
    <transition name="fade">
      <CardDelete
        v-if="isCardDelete"
        @close="isCardDelete = $event"
        @delete="deleteCard"
        :isSuggestion="false"
      />
    </transition>
    <transition name="fade">
      <!-- <CardDetail
        v-if="isDetail"
        @close="isDetail = $event"
        :detailData="detailData"
        isSuggestion="newests-card"
      /> -->
      <IdeaModal
        :detailData="detailData"
        v-if="isDetail"
        @close="isDetail = $event"
      />
    </transition>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  suggestionStatus,
  getInitials,
  dateDiff,
  fileTypeCheck,
} from "../../../helpers";
import { suggestionService, upload } from "../../../services";

import SuggestionSupport from "./Modals/SuggestionSupport.vue";
import CommentEdit from "./Modals/CommentEdit.vue";
import CardDelete from "./Modals/CardDelete.vue";
// import CardDetail from "./Modals/CardDetail.vue";
import LoadingCard from "./LoadingCard.vue";
import IdeaModal from "../../../components/MyIdea/Modals/IdeaModal.vue";

export default {
  name: "LikedCard",
  data() {
    return {
      commentId: null,
      whoLikedId: null,
      suggestionId: null,
      isSuggestionSupport: false,
      commentText: "",
      isCommentShowId: null,
      commnetEditId: null,
      commentEditData: null,
      isCommentEdit: false,
      cardDropdownId: null,
      isCardDelete: false,
      cardDeleteDataId: null,
      detailData: null,
      isDetail: false,
    };
  },
  components: {
    SuggestionSupport,
    CommentEdit,
    CardDelete,
    // CardDetail,
    IdeaModal,
    LoadingCard,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    mostLikedData() {
      return this.$store.getters["mostLiked/pageData"];
    },
  },
  filters: {
    suggestionStatusFilter(value) {
      return suggestionStatus(value);
    },
    getInitialsFilter(value) {
      return getInitials(value);
    },
    dateDiffFilter(value) {
      return dateDiff(value);
    },
  },
  methods: {
    isCommentFn(item) {
      this.commentId = this.commentId === item ? null : item;
    },
    whoLikedFn(item) {
      this.whoLikedId = this.whoLikedId === item ? null : item;
    },
    commentShowFn(item) {
      this.isCommentShowId = this.isCommentShowId === item ? null : item;
    },
    commentEditFn(item) {
      this.commnetEditId = this.commnetEditId === item ? null : item;
    },
    cardDropdownFn(item) {
      this.cardDropdownId = this.cardDropdownId === item ? null : item;
    },
    supportShowModal(item) {
      this.suggestionId = item;
      this.isSuggestionSupport = true;
    },
    clickOutSide(e) {
      let whoLikedList = this.$refs.whoLikedList
        ? this.$refs.whoLikedList[0]
        : null;
      let target = e.target;
      if (
        whoLikedList &&
        whoLikedList !== target &&
        !whoLikedList.contains(target)
      ) {
        this.whoLikedId = null;
      }
    },
    refreshData() {
      this.$store.dispatch("mostLiked/deleteMostLikedData");
      this.$store.dispatch("mostLiked/setData");
      this.$store.dispatch("suggestionNewests/deleteNewestsData");
      this.$store.dispatch("suggestionNewests/setData");
    },
    commentEditShowModal(item) {
      this.commentEditData = item;
      this.isCommentEdit = true;
      this.commnetEditId = null;
    },
    deleteCardShowModal(item) {
      this.isCardDelete = true;
      this.cardDeleteDataId = item;
      this.cardDropdownId = null;
    },
    async liked(item) {
      if (item.IsLiked) {
        //Delete Liked
        let id = null;
        item.Likes.forEach((element) => {
          if (element.UserId == this.user.Data.UserId) {
            id = element.Id;
          }
        });
        const body = {
          Id: id,
          SuggestionId: item.Id,
          UserId: this.user.Data.UserId,
        };
        const res = await suggestionService.fetchDeleteLike(body);
        if (res.Message == "like is deleted") {
          this.refreshData();
        }
      } else {
        //Create Liked
        const body = {
          UserId: this.user.Data.UserId,
          SuggestionId: item.Id,
        };
        const res = await suggestionService.fetchCreateLike(body);
        if (res.Data) {
          this.refreshData();
        }
      }
    },
    async createComment(item) {
      const body = {
        CommentText: this.commentText,
        SuggestionId: item.Id,
        UserId: this.user.Data.UserId,
      };
      try {
        const res = await suggestionService.fetchCreateComment(body);
        if (res.Data) {
          this.commentText = null;
          this.isCommentShowId = null;
          this.commentId = null;
          this.$toast.success("Yorumunuz başarıyla yapılmıştır.", {
            position: "top",
          });
          this.refreshData();
        }
      } catch (error) {
        this.$toast.error("Yorumunuz gönderilirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    async deleteComment(item) {
      const body = {
        Id: item.Id,
        SuggestionId: item.SuggestionId,
        UserId: item.UserId,
      };
      try {
        const res = await suggestionService.fetchDeleteComment(body);
        if (res.Message == "comment is deleted") {
          this.$toast.success("Yorumunuz başarıyla silindi.", {
            position: "top",
          });
          this.refreshData();
        }
      } catch (error) {
        this.$toast.error("Yorumunuz silinirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    async editComment(event) {
      const body = {
        Id: this.commentEditData.Id,
        CommentText: event,
        SuggestionId: this.commentEditData.SuggestionId,
        UserId: this.commentEditData.UserId,
      };
      try {
        const res = await suggestionService.fetchUpdateComment(body);
        if (res.Data) {
          this.$toast.success("Yorumunuz başarıyla güncellendi.", {
            position: "top",
          });
          this.refreshData();
          this.isCommentEdit = false;
        }
      } catch (error) {
        this.$toast.error("Yorumunuz güncellenirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    async deleteCard() {
      try {
        const res = await suggestionService.fetchDeleteSuggestion(
          this.cardDeleteDataId
        );
        if (res.Message == "Suggestion is deleted") {
          this.$toast.success("İşleminiz başarıyla tamamlandı.", {
            position: "top",
          });
          this.refreshData();
        } else {
          this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
            position: "top",
          });
        }
      } catch (error) {
        this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
          position: "top",
        });
      }
    },
    async editSuggestion(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      const tmpData = item;
      if (item.FileId != null) {
        const res = await upload.fetchDownloadFile(item.FileId);
        tmpData.DownloadFile = {
          DownloadBinary: res.Data.DownloadBinary,
          Extension: res.Data.Extension,
          Filename: res.Data.Filename,
          Id: res.Data.Id,
          fileType: fileTypeCheck(res.Data.Extension),
        };
      }
      this.$emit("edit-suggestion", tmpData);
      this.cardDropdownId = null;
      this.$store.dispatch("loading/setIsLoading", false);
    },
    async cardDetail(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      this.detailData = item;
      this.detailData.ProjectPresentationDocumentFiles = [];
      this.detailData.DownloadFile = [];

      if (this.detailData.FileIds && this.detailData.FileIds.length > 0) {
        for await (let element of this.detailData.FileIds) {
          const res = await upload.fetchDownloadFile(element);
          this.detailData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      if (
        this.detailData.ProjectPresentationDocumentIds &&
        this.detailData.ProjectPresentationDocumentIds.length > 0
      ) {
        for await (let element of this.detailData
          .ProjectPresentationDocumentIds) {
          const res = await upload.fetchDownloadFile(element);
          this.detailData.ProjectPresentationDocumentFiles.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isDetail = true;
    },
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
};
</script>

<style></style>
