<template>
  <div>
    <div
      id="modal-share-project-suggestion"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5)"
      @click="close"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" @click.stop>
          <div class="modal-header bg-orange rounded-0">
            <h5 class="modal-title text-center text-white mx-auto">
              PROJE FİKRİ GİRİŞİ
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form>
            <div class="modal-body">
              <input
                name="__RequestVerificationToken"
                type="hidden"
                value="_wNzemL7sBdcJMELtvmMipIIVrc-n7fJvoaWcUkQ5oSw1GfOveExqYXnh2OOtDTadXbym_Kv4p_JbPE4n6PM7NlwejyfeqxxA7H4lW3HAqqaHshLO_mz2QfrtrxT2H8wLPTfN5Y0soVX1ilJ9BAhVQ2"
              />
              <div class="form-group icon-user">
                <input
                  type="text"
                  class="form-control ui-autocomplete-input"
                  id="idea-recipient-project-name"
                  :value="user.Data.FullName"
                  disabled="'disabled'"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  id="idea-recipient-arge-userId"
                  value="f243b899-0fb6-4ce7-9611-f7c362ec4cdc"
                />
                <input
                  type="hidden"
                  id="hdnArgeAccountId"
                  value="3848c4f9-7f8f-e811-a857-000d3ab6bf6e"
                />
              </div>

              <div class="form-group">
                <label>1-) Proje Adı</label>
                <input
                  class="form-control short custom-placeholder"
                  id="txtTitle"
                  name="ProductIdeaTittle"
                  placeholder="Proje adı"
                  required="required"
                  type="text"
                  validationelement="1"
                  v-model="title"
                />
              </div>
              <div
                id="txtTitleError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('title') ? { display: 'block' } : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="form-group">
                <label>2-) Problem Tanımı (Mevcut Durum)</label>
                <textarea
                  class="form-control short plcText custom-placeholder"
                  cols="20"
                  id="txtProblemDefinition"
                  name="ProblemDefinition"
                  placeholder="•Problemin tanımı yapılmalı ve problem, göstergeler tanımlanarak sayısal ifadelerle desteklenmelidir.
 •Problemin şirket strateji ve hedefleri doğrultusunda olması beklenir. (Kalite, Hız, Maliyet, Büyüme, Müşteri Memnuniyeti vb.) 
 ÖRNEK: «Markamız için müşteri şikayetleri cevaplama süresi 6 gün olup bu süre diğer grup markalarımızın üzerindedir.»"
                  required="required"
                  rows="2"
                  validationelement="1"
                  v-model="problemDefinition"
                ></textarea>
              </div>
              <div
                id="txtProblemDefinitionError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('problemDefinition')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="form-group">
                <label>3-) Hedef Tanımı</label>
                <textarea
                  class="form-control short plcText custom-placeholder"
                  cols="20"
                  id="txtTargetDefinition"
                  name="TargetDefinition"
                  placeholder="•Problem tanımında ifade edilen göstergelerin hedef değerleri yer almalıdır. 
 •(başlangıç değer -> hedef değer; değişim % x) 
 ÖRNEK: «6 gün olan Müşteri şikayetleri cevaplama süresinin 4 güne indirilmesi»"
                  required="required"
                  rows="2"
                  validationelement="1"
                  v-model="targetDefinition"
                ></textarea>
              </div>
              <div
                id="txtTargetDefinitionError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('targetDefinition')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="form-group">
                <label>4-) Kapsam</label>
                <textarea
                  class="form-control short plcText custom-placeholder"
                  cols="20"
                  id="txtScope"
                  name="Scope"
                  placeholder="•Hangi süreçlerin, iş alanlarının veya markaların proje kapsamına dahil olacağı belirtilmelidir. 
 ÖRNEK: «xxx Markasına gelen tüm Müşteri Şikayetleri» 
 Ya da «İş tekrarı sebepli müşteri şikayetleri vb.»"
                  required="required"
                  rows="2"
                  validationelement="1"
                  v-model="scope"
                ></textarea>
              </div>
              <div
                id="txtScopeError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('scope') ? { display: 'block' } : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="form-group">
                <label>5-) Fırsat Tanımı (Yan Faydalar)</label>
                <textarea
                  class="form-control short plcText custom-placeholder"
                  cols="20"
                  id="txtOpportunityDefinition"
                  name="OpportunityDefinition"
                  placeholder="•Proje Hedefi dışında sağlanan kazanımlar burada fırsat olarak belirtilebilir. 
 •Mümkün olduğunca sayısal değerlerle fırsat tanımı desteklenmelidir. 
 ÖRNEK: Proje Hedefi «Müşteri Şikayet cevaplama süresinin 6 günden 4 güne indirilmesi» iken bu fırsata tekrar yazılmaz ancak projenin doğrudan hedefi olmayıp kazanımı olan «Bölge Yöneticileri ve Müşteri İlişkileri ekibinin 15 adam gün / yıl zaman tasarrufu» fırsat alanında belirtilir."
                  required="required"
                  rows="2"
                  validationelement="1"
                  v-model="opportunityDefinition"
                ></textarea>
              </div>
              <div
                id="txtOpportunityDefinitionError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('opportunityDefinition')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="mb-3">
                <label style="font: 500 14px/30px 'Raleway'"
                  >6-) Departman Seçimi</label
                >
                <multiselect
                  v-model="department"
                  tag-placeholder="Add this as new tag"
                  placeholder="Departman seçiniz"
                  label=""
                  track-by=""
                  :options="departmentsData"
                  :searchable="true"
                  style="padding: 0"
                  selectLabel="Seçmek için tıklayınız"
                  selectedLabel="Seçildi"
                  deselectLabel="Silmek için tıklayınız"
                >
                </multiselect>
              </div>
              <div
                id="txtSectionError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('department')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="mb-3" id="divManager">
                <label style="font: 500 14px/30px 'Raleway'"
                  >7-) Değer Akım Yöneticileri</label
                >
                <multiselect
                  v-model="valueCurrentId"
                  tag-placeholder="Add this as new tag"
                  placeholder="Değer akım yöneticisini seçiniz"
                  label="Name"
                  track-by="Id"
                  :options="currenValuelist"
                  :multiple="true"
                  :searchable="true"
                  :taggable="true"
                  @tag="addTag"
                  style="padding: 0"
                  selectLabel="Seçmek için tıklayınız"
                  selectedLabel="Seçildi"
                  deselectLabel="Silmek için tıklayınız"
                >
                </multiselect>
              </div>
              <div
                id="txtCurrentManagerError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('valueCurrentId')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>

              <div class="mb-3" id="divTeamLeader">
                <label style="font: 500 14px/30px 'Raleway'"
                  >8-) Ekip Liderleri</label
                >
                <div
                  class="d-flex align-items-center multiselectbox-icon-wrapper"
                >
                  <div class="multiselect-icon" style="padding: 12.5px 10px">
                    <b-icon icon="person"></b-icon>
                  </div>
                  <multiselect
                    v-model="teamLeader"
                    tag-placeholder="Add this as new tag"
                    placeholder="Ekip liderini seçiniz"
                    label="Name"
                    track-by="Id"
                    :options="optionTeamLeader"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    @tag="addTagTeamLeader"
                    style="padding: 0"
                    selectLabel="Seçmek için tıklayınız"
                    selectedLabel="Seçildi"
                    deselectLabel="Silmek için tıklayınız"
                  >
                  </multiselect>
                </div>
              </div>

              <div
                id="txtTeamLeaderError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('teamLeader')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>

              <div class="mb-3" id="divTeams">
                <label style="font: 500 14px/30px 'Raleway'"
                  >9-) Ekip Üyeleri</label
                >
                <div
                  class="d-flex align-items-center multiselectbox-icon-wrapper"
                >
                  <div class="multiselect-icon" style="padding: 12.5px 10px">
                    <b-icon icon="person"></b-icon>
                  </div>
                  <multiselect
                    v-model="team"
                    tag-placeholder="Add this as new tag"
                    placeholder="Ekip üyelerini seçiniz"
                    label="Name"
                    track-by="Id"
                    :options="optionTeam"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    @tag="addTagTeam"
                    style="padding: 0"
                    selectLabel="Seçmek için tıklayınız"
                    selectedLabel="Seçildi"
                    deselectLabel="Silmek için tıklayınız"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="form-group">
                <label>10-) Proje Başlangıç Tarihi</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text icon-calendar"
                      id="basic-addon1"
                    ></span>
                  </div>
                  <input
                    class="datepicker form-control"
                    data-val="true"
                    data-val-date="The field ProjectStartDate must be a date."
                    id="startDate"
                    name="ProjectStartDate"
                    type="datetime-local"
                    v-model="projectStartDate"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>11-) Proje Bitiş Tarihi</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text icon-calendar"
                      id="basic-addon1"
                    ></span>
                  </div>
                  <input
                    class="datepicker form-control"
                    data-val="true"
                    data-val-date="The field ProjectEndDate must be a date."
                    id="endDate"
                    name="ProjectEndDate"
                    type="datetime-local"
                    v-model="projectEndDate"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  >12-) Marka/Departman arası yaygınlaştırılabilir mi?</label
                ><br />
                <div class="form-check form-check-inline ml-3">
                  <input
                    class="form-check-input h-auto"
                    type="radio"
                    name="spreading-action"
                    id="cbx-spreading-yes"
                    value="1"
                    v-model="spreading"
                  />
                  <label class="form-check-label" for="cbx-spreading-yes"
                    >Evet</label
                  >
                </div>
                <div class="form-check form-check-inline ml-3">
                  <input
                    class="form-check-input h-auto"
                    type="radio"
                    name="spreading-action"
                    id="cbx-spreading-no"
                    value="0"
                    v-model="spreading"
                  />
                  <label
                    class="form-check-label"
                    for="cbx-spreading-no"
                    data-toggle="collapse"
                    >Hayır</label
                  >
                </div>
              </div>

              <div class="form-group">
                <label
                  >13-) Projenin hayata geçirilebilmesi için IT geliştirmesi
                  gerekli mi?</label
                ><br />
                <div class="form-check form-check-inline ml-3">
                  <input
                    class="form-check-input h-auto"
                    type="radio"
                    name="it-action"
                    id="cbx-it-yes"
                    value="1"
                    v-model="ITDevolopment"
                  />
                  <label class="form-check-label" for="cbx-it-yes">Evet</label>
                </div>
                <div class="form-check form-check-inline ml-3">
                  <input
                    class="form-check-input h-auto"
                    type="radio"
                    name="it-action"
                    id="cbx-it-no"
                    value="0"
                    v-model="ITDevolopment"
                  />
                  <label
                    class="form-check-label"
                    for="cbx-it-no"
                    data-toggle="collapse"
                    >Hayır</label
                  >
                </div>
              </div>

              <div v-if="!editPagedData" class="form-group">
                <label
                  >14-) Eklemek istediğiniz dokümanı aşağıdan yükleyiniz.</label
                >
                <div
                  class="input-group mb-3"
                  v-for="(item, key) in fileUploadData"
                  :key="key"
                >
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text icon-folder-alt"
                      id="basic-addon1"
                    ></span>
                  </div>
                  <p
                    class="form-control d-flex align-items-center cursor-default"
                    style="border: 0px"
                  >
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFileArge"
                      name="customFile"
                      ref="fileUploadInput"
                      multiple
                      :accept="supportedFileType"
                      @change="fileUploadChange"
                    />
                    <label
                      class="custom-file-label hide-overflow-text selected"
                      for="customFile"
                      id="lblDocument"
                    >
                      {{ item.Filename
                      }}{{ item.Extension ? `.${item.Extension}` : "" }}
                    </label>
                  </p>
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-danger fas fa-trash-alt"
                      :disabled="fileId.length == 0"
                      @click="deleteDocument(item.Id)"
                    ></button>
                  </div>
                </div>
                <small
                  class="Raleway-Medium"
                  :style="[isFileMB ? { color: 'red' } : '']"
                  >*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.</small
                >
                <br />
                <small class="Raleway-Medium"
                  >**Birden fazla doküman yüklemek için sıkıştırılmış dosya
                  (.zip vb.) kullanabilirsiniz.</small
                >
              </div>
              <div v-if="editPagedData" class="form-group">
                <label>14-) Proje Sunumu Yükleyiniz</label>
                <div
                  class="input-group mb-3"
                  v-for="(item, key) in fileUploadProjectDocumentData"
                  :key="key"
                >
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text icon-folder-alt"
                      id="basic-addon1"
                    ></span>
                  </div>
                  <p
                    class="form-control d-flex align-items-center cursor-default"
                    style="border: 0px"
                  >
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFileArge"
                      name="customFile"
                      ref="fileUploadInput"
                      multiple
                      :accept="supportedFileType"
                      @change="fileUploadChangeProjectDocument"
                    />
                    <label
                      class="custom-file-label hide-overflow-text selected"
                      for="customFile"
                      id="lblDocument"
                      >{{ item.Filename
                      }}{{ item.Extension ? `.${item.Extension}` : "" }}</label
                    >
                  </p>
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-danger fas fa-trash-alt"
                      :disabled="projectPresentationDocument.length == 0"
                      @click="deleteProjectDocument(item.Id)"
                    ></button>
                  </div>
                </div>
              </div>
              <label v-if="editPagedData" style="font: 500 14px/30px 'Raleway'"
                >15-) Taslak Proje Bildiri Formatını İndir</label
              >
              <div
                class="form-group custom-file icon-folder-alt"
                v-if="editPagedData && editPagedData.ProjeDocument"
              >
                <p
                  class="form-control d-flex align-items-center cursor-default"
                >
                  <span
                    class="flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                    >{{ editPagedData.ProjeDocument.Filename
                    }}{{ editPagedData.ProjeDocument.Extension }}</span
                  >
                  <a
                    :href="
                      'data:' +
                      editPagedData.ProjeDocument.fileType +
                      ';base64,' +
                      editPagedData.ProjeDocument.DownloadBinary
                    "
                    :download="
                      editPagedData.ProjeDocument.Filename +
                      '.' +
                      editPagedData.ProjeDocument.Extension
                    "
                    class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                    title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                    alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                    target="_blank"
                  >
                  </a>
                </p>
              </div>
              <hr class="mt-4" v-if="editPagedData" />
              <div class="form-group" v-if="editPagedData">
                <label>
                  Proje ile ilgili yüklemek istediğiniz diğer dokümanları bu
                  alandan yükleyebilirsiniz.</label
                >
                <div
                  class="input-group mb-3"
                  v-for="(item, key) in fileUploadData"
                  :key="key"
                >
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text icon-folder-alt"
                      id="basic-addon1"
                    ></span>
                  </div>
                  <p
                    class="form-control d-flex align-items-center cursor-default"
                    style="border: 0px"
                  >
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFileArge"
                      name="customFile"
                      ref="fileUploadInput"
                      multiple
                      :accept="supportedFileType"
                      @change="fileUploadChange"
                    />
                    <label
                      class="custom-file-label hide-overflow-text selected"
                      for="customFile"
                      id="lblDocument"
                    >
                      {{ item.Filename
                      }}{{ item.Extension ? `.${item.Extension}` : "" }}
                    </label>
                  </p>
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-danger fas fa-trash-alt"
                      :disabled="fileId.length == 0"
                      @click="deleteDocument(item.Id)"
                    ></button>
                  </div>
                </div>
                <small
                  class="Raleway-Medium"
                  :style="[isFileMB ? { color: 'red' } : '']"
                  >*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.</small
                >
                <br />
                <small class="Raleway-Medium"
                  >**Birden fazla doküman yüklemek için sıkıştırılmış dosya
                  (.zip vb.) kullanabilirsiniz.</small
                >
              </div>
              <div
                class="form-group custom-file icon-folder-alt"
                v-if="editPagedData && downloadFile.length > 0"
              >
                <p
                  class="form-control d-flex align-items-center cursor-default"
                  v-for="item in downloadFile"
                  :key="item.Id"
                >
                  <span
                    class="flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                    >{{ item.Filename }}.{{ item.Extension }}</span
                  >
                  <a
                    :href="
                      'data:' + item.fileType + ';base64,' + item.DownloadBinary
                    "
                    :download="item.Filename + '.' + item.Extension"
                    class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                    title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                    alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                    target="_blank"
                  >
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-light text-danger ml-3 fas fa-trash-alt"
                    id="document-remove"
                    title="Dokümanı silmek için tıklayın."
                    alt="Dokümanı silmek için tıklayın."
                    @click="deleteDocument(item.Id)"
                  ></a>
                </p>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center">
              <button
                v-if="!editPagedData && isDraft"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-info"
                :disabled="isBtnDisabled"
                @click="saveDraft"
              >
                <span class="mr-2">Taslak Olarak Kaydet</span>
                <b-icon icon="bookmark"></b-icon>
              </button>
              <button
                v-if="editPagedData && isDraft"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-info"
                :disabled="isBtnDisabled"
                @click="editDraft"
              >
                <span class="mr-2">Taslağı Düzenle</span>
                <b-icon icon="bookmark"></b-icon>
              </button>
              <button
                v-if="editPagedData && !isDraft"
                @click="editSuggestion"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-orange"
                :disabled="isBtnDisabled"
              >
                DÜZENLE <span class="icon-arrow-right pl-2"></span>
              </button>
              <button
                v-else-if="editPagedData && isDraft"
                @click="editSuggestion"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-orange"
                :disabled="isBtnDisabled"
              >
                GÖNDER <span class="icon-arrow-right pl-2"></span>
              </button>
              <button
                v-else
                type="button"
                @click="createProjectSuggestion"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-orange"
                :disabled="isBtnDisabled"
              >
                GÖNDER <span class="icon-arrow-right pl-2"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isExitmodal"
        id="Warning"
        class="modal fade show"
        style="height: 200px; display: block"
        role="dialog"
        data-backdrop="false"
      >
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-body">
              <p>Fikri paylaşmadan çıkmak istediğinize emin misiniz?</p>
              <button
                type="button"
                class="btn btn-danger confirmclosed"
                style="margin-left: 27%"
                @click.stop="exitModal"
              >
                Çık
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2"
                data-dismiss="modal"
                @click.stop="isExitmodal = false"
              >
                Devam Et
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  toBase64,
  fileTypeCheck,
  bytesToMegaBytes,
  isNil,
} from "../../helpers";
import { suggestionRdService, upload } from "../../services";
import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "AutomotiveProduct",
  data() {
    return {
      title: "",
      problemDefinition: "",
      targetDefinition: "",
      scope: "",
      department: "",
      opportunityDefinition: "",
      projectStartDate: null,
      projectEndDate: null,
      spreading: null,
      ITDevolopment: null,
      valueCurrentId: [],
      currenValuelist: [],
      teamLeader: [],
      optionTeamLeader: [],
      team: [],
      optionTeam: [],
      fileUploadData: [
        {
          Extension: "",
          Filename: "Doküman Yükle +",
          Id: 0,
        },
      ],
      fileUploadProjectDocumentData: [
        {
          Extension: "",
          Filename: "Doküman Yükle +",
          Id: 0,
        },
      ],
      isFileMB: false,
      fileId: [],
      requiredField: [],
      isBtnDisabled: false,
      downloadFile: [],
      projectPresentationDocument: [],
      isExitmodal: false,
      supportedFileType:
        "image/jpeg,image/jpg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/zip,application/pdf",
    };
  },
  components: {
    Multiselect,
  },
  props: {
    editPagedData: {
      type: Object,
    },
    isIdea: {
      type: String,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    departmentsData() {
      return this.$store.getters["suggestionRD/departmentsData"];
    },
    getUsers() {
      return this.$store.getters["user/getUsers"];
    },
  },
  watch: {
    getUsers: {
      handler: function (val) {
        // Return the object that changed
        val.forEach((element) => {
          this.setUsers(element);
        });
      },
      deep: true,
    },
  },
  async created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    if (this.departmentsData.length == 0) {
      await this.$store.dispatch("suggestionRD/getDepartments");
    }

    if (!this.getUsers) {
      await this.$store.dispatch("user/getUsers");
    } else {
      this.getUsers.forEach((element) => {
        this.setUsers(element);
      });
    }
    if (this.editPagedData) {
      this.fileId = this.editPagedData.FileIds;
      this.title = this.editPagedData.Title;
      this.problemDefinition = this.editPagedData.ProblemDefinition;
      this.targetDefinition = this.editPagedData.TargetDefinition;
      this.scope = this.editPagedData.Scope;
      this.opportunityDefinition = this.editPagedData.OpportunityDefinition;
      this.department = this.editPagedData.Department;
      this.projectStartDate = this.newDateFormatter(
        this.editPagedData.ProjectStartDate
      );
      this.projectEndDate = this.newDateFormatter(
        this.editPagedData.ProjectEndDate
      );
      (this.spreading = this.editPagedData.Spreading == true ? "1" : "0"),
        (this.ITDevolopment =
          this.editPagedData.ITDevolopment == true ? "1" : "0");

      this.editPagedData.SuggestionRDUserModels.forEach((element) => {
        if (element.UserStatus == "4") {
          this.valueCurrentId.push({
            Id: element.UserId,
            Name: element.UserName,
          });
        }
        if (element.UserStatus == "2") {
          this.team.push({
            Id: element.UserId,
            Name: element.UserName,
          });
        }
        if (element.UserStatus == "3") {
          this.teamLeader.push({
            Id: element.UserId,
            Name: element.UserName,
          });
        }
      });
      this.downloadFile = this.editPagedData.DownloadFile;
    }
  },
  methods: {
    setUsers(element) {
      this.optionTeamLeader.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
      this.optionTeam.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
      this.currenValuelist.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
    },
    newDateFormatter(date) {
      if (date == null) return null;
      else {
        const newDate = new Date(date);
        const dateStr =
          newDate.getFullYear() +
          "-" +
          ("00" + (newDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + newDate.getDate()).slice(-2) +
          "T" +
          ("00" + newDate.getHours()).slice(-2) +
          ":" +
          ("00" + newDate.getMinutes()).slice(-2);
        return dateStr;
      }
    },
    async fileUploadChange(event) {
      this.fileUploadData = [];
      const files = [...event.srcElement.files];
      files.forEach(async (file, index) => {
        if (Math.round(bytesToMegaBytes(file.size)) <= 40) {
          const fileName = file.name.substring(0, file.name.lastIndexOf("."));
          const docExtension = file.name.substring(
            file.name.lastIndexOf(".") + 1,
            file.name.length
          );
          const base64 = await toBase64(file);
          const splitBase64 = await base64.split(",")[1];
          const body = {
            Filename: fileName,
            Extension: docExtension,
            DownloadBinary: splitBase64,
            ContentType: fileTypeCheck(docExtension),
          };

          try {
            this.$store.dispatch("loading/setIsLoading", true);
            const res = await upload.fetchUpluadFile(body);
            this.isFileMB = false;
            this.fileId.push(res.Data.Id);
            this.fileUploadData.push(res.Data);
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.success("Dosya başarıyla yüklendi.", {
              position: "top",
            });
          } catch (error) {
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
              position: "top",
            });
            this.deleteDocument(this.fileId[index]);
          }
        } else {
          this.isFileMB = true;
          this.deleteDocument(this.fileId[index]);
        }
      });
      this.$store.dispatch("loading/setIsLoading", false);
    },
    async fileUploadChangeProjectDocument(event) {
      this.fileUploadProjectDocumentData = [];
      const files = [...event.srcElement.files];
      files.forEach(async (file, index) => {
        if (Math.round(bytesToMegaBytes(file.size)) <= 40) {
          const Str = file.name.split(".");
          const fileName = Str[0];
          const docExtension = Str[1];
          const base64 = await toBase64(file);
          const splitBase64 = base64.split(",")[1];
          const body = {
            Filename: fileName,
            Extension: docExtension,
            DownloadBinary: splitBase64,
            ContentType: fileTypeCheck(docExtension),
          };
          try {
            this.$store.dispatch("loading/setIsLoading", true);
            const res = await upload.fetchUpluadFile(body);
            this.fileUploadProjectDocumentData.push(res.Data);
            this.projectPresentationDocument.push(res.Data.Id);
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.success("Dosya başarıyla yüklendi.", {
              position: "top",
            });
          } catch (error) {
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
              position: "top",
            });
            this.deleteProjectDocument(this.projectPresentationDocument[index]);
          }
        } else {
          this.deleteProjectDocument(this.projectPresentationDocument[index]);
        }
      });
    },
    async deleteProjectDocument(Id) {
      this.$store.dispatch("loading/setIsLoading", true);
      try {
        await upload.fetchDeleteFile(Id);
        const uploadFile = this.$refs.fileUploadInput;
        uploadFile.value = null;
        this.projectPresentationDocument =
          this.projectPresentationDocument.filter((f) => f != Id);
        this.fileUploadProjectDocumentData =
          this.fileUploadProjectDocumentData.filter((f) => f.Id != Id);
        if (this.fileUploadProjectDocumentData.length == 0) {
          this.fileUploadProjectDocumentData.push({
            Extension: "",
            Filename: "Doküman Yükle +",
            Id: 0,
          });
        }
      } catch (error) {
        //console.log("error->", error);
      }
      this.$store.dispatch("loading/setIsLoading", false);
    },
    async deleteDocument(Id) {
      this.$store.dispatch("loading/setIsLoading", true);
      try {
        await upload.fetchDeleteFile(Id);
        this.fileUploadData = this.fileUploadData.filter((f) => f.Id != Id);
        if (this.fileUploadData.length == 0) {
          this.fileUploadData.push({
            Extension: "",
            Filename: "Doküman Yükle +",
            Id: 0,
          });
        }
        this.downloadFile = this.downloadFile.filter((f) => f.Id != Id);
        const uploadFile = this.$refs.fileUploadInput;
        uploadFile.value = null;
        this.fileId = this.fileId.filter((f) => f != Id);
      } catch (error) {
        //console.log("error->", error);
      }
      this.$store.dispatch("loading/setIsLoading", false);
    },
    addTag(newTag) {
      const tag = {
        Name: newTag,
        Id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.currenValuelist.push(tag);
      this.valueCurrentId.push(tag);
    },
    addTagTeamLeader(newTag) {
      const tag = {
        Name: newTag,
        Id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.teamLeader.push(tag);
      this.optionTeamLeader.push(tag);
    },
    addTagTeam(newTag) {
      const tag = {
        Name: newTag,
        Id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.team.push(tag);
      this.optionTeam.push(tag);
    },
    exitModal() {
      this.isExitmodal = false;
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    close({ isSaveEditBtn = false }) {
      if (!isSaveEditBtn && this.isOutsideClick()) {
        this.isExitmodal = true;
      } else {
        this.$emit("close", false);
        const $body = document.querySelector("body");
        $body.style.removeProperty("overflow");
      }
    },
    isOutsideClick() {
      if (
        !isNil(this.title) ||
        !isNil(this.problemDefinition) ||
        !isNil(this.targetDefinition) ||
        !isNil(this.scope) ||
        !isNil(this.opportunityDefinition) ||
        !isNil(this.department) ||
        this.valueCurrentId.length != 0 ||
        this.teamLeader.length != 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isRequiredFieldCheck() {
      if (
        !isNil(this.title) &&
        !isNil(this.problemDefinition) &&
        !isNil(this.targetDefinition) &&
        !isNil(this.scope) &&
        !isNil(this.opportunityDefinition) &&
        !isNil(this.department) &&
        this.valueCurrentId.length != 0 &&
        this.teamLeader.length != 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    requiredFieldFill() {
      this.requiredField = [];
      if (isNil(this.title)) this.requiredField.push("title");
      if (isNil(this.problemDefinition))
        this.requiredField.push("problemDefinition");
      if (isNil(this.targetDefinition))
        this.requiredField.push("targetDefinition");
      if (isNil(this.scope)) this.requiredField.push("scope");
      if (isNil(this.opportunityDefinition))
        this.requiredField.push("opportunityDefinition");
      if (isNil(this.department)) this.requiredField.push("department");
      if (this.valueCurrentId.length == 0)
        this.requiredField.push("valueCurrentId");
      if (this.teamLeader.length == 0) this.requiredField.push("teamLeader");
    },
    async createProjectSuggestion() {
      if (this.isRequiredFieldCheck()) {
        try {
          this.isBtnDisabled = true;
          let valueCurrentManager = [];
          let tmpTeamLeader = [];
          let tmpTeam = [];
          this.valueCurrentId.forEach((element) => {
            valueCurrentManager.push({ UserId: element.Id, UserStatus: 4 });
          });
          this.teamLeader.forEach((element) => {
            tmpTeamLeader.push({ UserId: element.Id, UserStatus: 3 });
          });
          this.team.forEach((element) => {
            tmpTeam.push({ UserId: element.Id, UserStatus: 2 });
          });
          const body = {
            FileIds: this.fileId,
            SuggestionStatusId: 1,
            SuggestionTypeId: 3,
            CompanyId: this.user.Data.CompanyId,
            GroupCompanyId: this.user.Data.GroupCompanyId,
            CreatedCustomerId: this.user.Data.UserId,
            Title: this.title,
            ProblemDefinition: this.problemDefinition,
            TargetDefinition: this.targetDefinition,
            Scope: this.scope,
            OpportunityDefinition: this.opportunityDefinition,
            Department: this.department,
            SuggestionRDUserModels: [
              ...valueCurrentManager,
              ...tmpTeamLeader,
              ...tmpTeam,
            ],
            ProjectStartDate: this.projectStartDate ? new Date(this.projectStartDate) : null,
            ProjectEndDate: this.projectEndDate ? new Date(this.projectEndDate) : null,
            Spreading: this.spreading == "1" ? true : false,
            ITDevolopment: this.ITDevolopment == "1" ? true : false,
          };
          await suggestionRdService.fetchCreateSuggesitonRD(body);
          this.$toast.success("Proje Fikriniz Başarıyla Oluşturuldu.", {
            position: "top",
          });
          this.$store.dispatch("suggestionRD/deletePagedData");
          this.$store.dispatch("suggestionRD/setData");
          this.close({ isSaveEditBtn: true });
        } catch (error) {
          this.$toast.error("Proje fikriniz paylaşılırken hata oluştu.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        this.requiredFieldFill();
      }
    },
    async editSuggestion() {
      if (this.isRequiredFieldCheck()) {
        try {
          this.isBtnDisabled = true;
          let valueCurrentManager = [];
          let tmpTeamLeader = [];
          let tmpTeam = [];
          const tmpData = this.editPagedData;
          tmpData.SuggestionStatusId = 2;
          tmpData.ProjectPresentationDocumentIds =
            this.projectPresentationDocument;
          tmpData.FileIds = this.fileId;
          tmpData.Title = this.title;
          tmpData.ProblemDefinition = this.problemDefinition;
          tmpData.TargetDefinition = this.targetDefinition;
          tmpData.Scope = this.scope;
          tmpData.OpportunityDefinition = this.opportunityDefinition;
          tmpData.Department = this.department;
          tmpData.ProjectStartDate = this.projectStartDate ? new Date(this.projectStartDate) : null;
          tmpData.ProjectEndDate = this.projectEndDate ? new Date(this.projectEndDate) : null;
          tmpData.Spreading = this.spreading == "1" ? true : false;
          tmpData.ITDevolopment = this.ITDevolopment == "1" ? true : false;
          this.valueCurrentId.forEach((element) => {
            valueCurrentManager.push({ UserId: element.Id, UserStatus: 4 });
          });
          this.teamLeader.forEach((element) => {
            tmpTeamLeader.push({ UserId: element.Id, UserStatus: 3 });
          });
          this.team.forEach((element) => {
            tmpTeam.push({ UserId: element.Id, UserStatus: 2 });
          });
          tmpData.SuggestionRDUserModels = [
            ...valueCurrentManager,
            ...tmpTeamLeader,
            ...tmpTeam,
          ];
          this.isDraft ? (tmpData.SuggestionStatusId = 1) : "";
          await suggestionRdService.fetchUpdateSuggestionRD(tmpData);
          this.$toast.success("Proje Fikriniz başarıyla oluşturuldu. Ana sayfadan görüntüleyebilirsiniz.", {
            position: "top",
          });
          this.$store.dispatch("suggestionRD/deletePagedData");
          this.$store.dispatch("suggestionRD/setData");
          if (this.isIdea == "my-idea") {
            this.$store.dispatch("ideas/deleteProductData");
            this.$store.dispatch("ideas/setProductData", {
              pageSize: 15,
              pageIndex: 0,
              createdUserId: this.user.Data.UserId,
              loginUserId: this.user.Data.UserId,
            });
          }
          if (this.isDraft) {
            const body = {
              pageIndex: 0,
              pageSize: 15,
              suggestionStatuses: [11],
              createdUserId: this.user.Data.UserId,
              loginUserId: this.user.Data.UserId,
            };
            this.$store.dispatch("draft/deleteDraftProductData");
            this.$store.dispatch("draft/setDraftProductData", body);
          }
          this.close({ isSaveEditBtn: true });
        } catch (error) {
          this.$toast.error("Güncelleştirme başarısız.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        this.requiredFieldFill();
      }
    },
    async saveDraft() {
      try {
        this.isBtnDisabled = true;
        let valueCurrentManager = [];
        let tmpTeamLeader = [];
        let tmpTeam = [];
        this.valueCurrentId.forEach((element) => {
          valueCurrentManager.push({ UserId: element.Id, UserStatus: 4 });
        });
        this.teamLeader.forEach((element) => {
          tmpTeamLeader.push({ UserId: element.Id, UserStatus: 3 });
        });
        this.team.forEach((element) => {
          tmpTeam.push({ UserId: element.Id, UserStatus: 2 });
        });
        const body = {
          FileIds: this.fileId,
          SuggestionStatusId: 11,
          SuggestionTypeId: 3,
          CompanyId: this.user.Data.CompanyId,
          GroupCompanyId: this.user.Data.GroupCompanyId,
          CreatedCustomerId: this.user.Data.UserId,
          Title: this.title,
          ProblemDefinition: this.problemDefinition,
          TargetDefinition: this.targetDefinition,
          Scope: this.scope,
          OpportunityDefinition: this.opportunityDefinition,
          Department: this.department,
          SuggestionRDUserModels: [
            ...valueCurrentManager,
            ...tmpTeamLeader,
            ...tmpTeam,
          ],
          ProjectStartDate: this.projectStartDate ? new Date(this.projectStartDate) : null,
          ProjectEndDate: this.projectEndDate ? new Date(this.projectEndDate) : null,
          Spreading: this.spreading == "1" ? true : false,
          ITDevolopment: this.ITDevolopment == "1" ? true : false,
        };
        await suggestionRdService.fetchCreateSuggesitonRD(body);
        this.$toast.success(
          "Taslak olarak kaydedilen proje fikrinize Fikirlerim sekmesinden ulaşabilirsiniz.",
          {
            position: "top",
          }
        );
        this.$store.dispatch("suggestionRD/deletePagedData");
        this.$store.dispatch("suggestionRD/setData");
        this.close({ isSaveEditBtn: true });
      } catch (error) {
        this.$toast.error(
          "Proje fikiri taslak olarak kaydedilirken hata oluştu.",
          {
            position: "top",
          }
        );
      }
      this.isBtnDisabled = false;
    },
    async editDraft() {
      try {
        this.isBtnDisabled = true;
        let valueCurrentManager = [];
        let tmpTeamLeader = [];
        let tmpTeam = [];
        const tmpData = this.editPagedData;
        tmpData.SuggestionStatusId = 11;
        tmpData.ProjectPresentationDocumentIds =
          this.projectPresentationDocument;
        tmpData.FileIds = this.fileId;
        tmpData.Title = this.title;
        tmpData.ProblemDefinition = this.problemDefinition;
        tmpData.TargetDefinition = this.targetDefinition;
        tmpData.Scope = this.scope;
        tmpData.OpportunityDefinition = this.opportunityDefinition;
        tmpData.Department = this.department;
        tmpData.ProjectStartDate = this.projectStartDate ? new Date(this.projectStartDate) : null;
        tmpData.ProjectEndDate = this.projectEndDate ? new Date(this.projectEndDate) : null;
        tmpData.Spreading = this.spreading == "1" ? true : false;
        tmpData.ITDevolopment = this.ITDevolopment == "1" ? true : false;
        this.valueCurrentId.forEach((element) => {
          valueCurrentManager.push({ UserId: element.Id, UserStatus: 4 });
        });
        this.teamLeader.forEach((element) => {
          tmpTeamLeader.push({ UserId: element.Id, UserStatus: 3 });
        });
        this.team.forEach((element) => {
          tmpTeam.push({ UserId: element.Id, UserStatus: 2 });
        });
        tmpData.SuggestionRDUserModels = [
          ...valueCurrentManager,
          ...tmpTeamLeader,
          ...tmpTeam,
        ];
        await suggestionRdService.fetchUpdateSuggestionRD(tmpData);
        this.$toast.success("Ürün fikir paylaşımı başarıyla yapıldı.", {
          position: "top",
        });
        this.$store.dispatch("draft/deleteDraftProductData");
        const body = {
          pageIndex: 0,
          pageSize: 15,
          suggestionStatuses: [11],
          createdUserId: this.user.Data.UserId,
          loginUserId: this.user.Data.UserId,
        };
        this.$store.dispatch("draft/setDraftProductData", body);
        this.close({ isSaveEditBtn: true });
      } catch (error) {
        this.$toast.error("Ürün fikirleri paylaşılırken hata oluştu.", {
          position: "top",
        });
      }
      this.isBtnDisabled = false;
    },
  },
};
</script>

<style></style>
