<template>
  <svg viewBox="0 0 25.51 34.02" style="height: 40px; margin-left: 10px;">
    <title>Asset 7</title>
    <g>
      <g>
        <path
          class="cls-1"
          d="M17.83,23.55a2,2,0,0,0-3.09.88l4.67,8.45,1.11-4,3.88,1.14-3.87-6.74A1.94,1.94,0,0,1,17.83,23.55Z"
        ></path>
        <path
          class="cls-1"
          d="M7.68,23.55A1.94,1.94,0,0,1,5,23.31L1.11,30.05,5,28.91l1.11,4,4.67-8.45A2,2,0,0,0,7.68,23.55Z"
        ></path>
        <path
          class="cls-2"
          d="M14.68,2h0a2,2,0,0,0,3.15,1h0a2,2,0,0,1,3.11,2.32h0a2.06,2.06,0,0,0,1.95,2.75h0a2.06,2.06,0,0,1,1.19,3.75h0a2.08,2.08,0,0,0,0,3.39h0a2.07,2.07,0,0,1-1.19,3.76h0a2,2,0,0,0-1.95,2.74h0a2,2,0,0,1-3.11,2.32h0a2,2,0,0,0-3.15,1.05h0a2,2,0,0,1-3.85,0h0a2,2,0,0,0-3.15-1.05h0A2,2,0,0,1,4.57,21.8h0a2,2,0,0,0-1.95-2.74h0A2.07,2.07,0,0,1,1.43,15.3h0a2.08,2.08,0,0,0,0-3.39h0A2.06,2.06,0,0,1,2.62,8.16h0a2.06,2.06,0,0,0,2-2.75h0A2,2,0,0,1,7.68,3.09h0a2,2,0,0,0,3.15-1h0A2,2,0,0,1,14.68,2Z"
        ></path>
        <ellipse
          class="cls-3"
          cx="12.76"
          cy="13.61"
          rx="7.76"
          ry="7.94"
        ></ellipse>
        <path
          d="M12.76,27.21h0a2.52,2.52,0,0,1-2.46-1.88,1.47,1.47,0,0,0-1-1A1.42,1.42,0,0,0,8,24.57,2.46,2.46,0,0,1,5,24.62a2.62,2.62,0,0,1-.91-3,1.51,1.51,0,0,0-.2-1.38,1.4,1.4,0,0,0-1.21-.61A2.53,2.53,0,0,1,.13,17.81a2.6,2.6,0,0,1,1-3,1.49,1.49,0,0,0,.63-1.23,1.48,1.48,0,0,0-.63-1.23,2.62,2.62,0,0,1-1-3A2.51,2.51,0,0,1,2.64,7.59,1.43,1.43,0,0,0,3.85,7a1.51,1.51,0,0,0,.2-1.38A2.6,2.6,0,0,1,5,2.6,2.46,2.46,0,0,1,8,2.64a1.4,1.4,0,0,0,1.33.24,1.45,1.45,0,0,0,1-1,2.54,2.54,0,0,1,4.91,0h0a1.47,1.47,0,0,0,.95,1,1.39,1.39,0,0,0,1.33-.24,2.48,2.48,0,0,1,3.06-.05,2.6,2.6,0,0,1,.91,3A1.51,1.51,0,0,0,21.66,7a1.47,1.47,0,0,0,1.21.61A2.52,2.52,0,0,1,25.38,9.4a2.62,2.62,0,0,1-1,3,1.51,1.51,0,0,0,0,2.45,2.62,2.62,0,0,1,1,3,2.55,2.55,0,0,1-2.51,1.81,1.39,1.39,0,0,0-1.21.61,1.51,1.51,0,0,0-.2,1.38,2.59,2.59,0,0,1-.91,3,2.48,2.48,0,0,1-3.06-.05,1.42,1.42,0,0,0-1.33-.24,1.47,1.47,0,0,0-.95,1A2.52,2.52,0,0,1,12.76,27.21ZM8.91,23.13a2.42,2.42,0,0,1,.79.13A2.58,2.58,0,0,1,11.36,25a1.44,1.44,0,0,0,2.79,0,2.61,2.61,0,0,1,1.67-1.76,2.52,2.52,0,0,1,2.35.42,1.38,1.38,0,0,0,1.73,0A1.47,1.47,0,0,0,20.42,22a2.7,2.7,0,0,1,.34-2.42,2.54,2.54,0,0,1,2.14-1.08,1.43,1.43,0,0,0,1.42-1,1.45,1.45,0,0,0-.56-1.69,2.67,2.67,0,0,1,0-4.33,1.44,1.44,0,0,0,.56-1.69,1.41,1.41,0,0,0-1.42-1,2.53,2.53,0,0,1-2.14-1.08,2.7,2.7,0,0,1-.34-2.42,1.47,1.47,0,0,0-.52-1.71,1.38,1.38,0,0,0-1.73,0A2.52,2.52,0,0,1,15.82,4,2.61,2.61,0,0,1,14.15,2.2a1.44,1.44,0,0,0-2.79,0A2.61,2.61,0,0,1,9.7,4a2.54,2.54,0,0,1-2.36-.42,1.38,1.38,0,0,0-1.73,0,1.47,1.47,0,0,0-.52,1.71,2.66,2.66,0,0,1-.34,2.43A2.55,2.55,0,0,1,2.61,8.72a1.42,1.42,0,0,0-1.42,1,1.46,1.46,0,0,0,.56,1.69,2.66,2.66,0,0,1,1.11,2.17,2.66,2.66,0,0,1-1.11,2.16,1.46,1.46,0,0,0-.56,1.69,1.41,1.41,0,0,0,1.42,1,2.54,2.54,0,0,1,2.14,1.08A2.67,2.67,0,0,1,5.09,22a1.47,1.47,0,0,0,.52,1.71,1.38,1.38,0,0,0,1.73,0A2.53,2.53,0,0,1,8.91,23.13Z"
        ></path>
        <path
          d="M12.65,22.2a8.43,8.43,0,0,1-8.32-8.51,8.42,8.42,0,0,1,8.32-8.5A8.42,8.42,0,0,1,21,13.69,8.42,8.42,0,0,1,12.65,22.2Zm0-15.88a7.3,7.3,0,0,0-7.21,7.37,7.29,7.29,0,0,0,7.21,7.37,7.29,7.29,0,0,0,7.2-7.37A7.3,7.3,0,0,0,12.65,6.32Z"
        ></path>
        <path
          d="M19.41,34a.55.55,0,0,1-.48-.29l-4.67-8.45a.58.58,0,0,1,0-.48,2.6,2.6,0,0,1,1.7-1.58,2.53,2.53,0,0,1,2.25.45h0a1.38,1.38,0,0,0,1.95-.18.55.55,0,0,1,.47-.17.57.57,0,0,1,.42.27l3.87,6.74a.58.58,0,0,1,0,.63.56.56,0,0,1-.59.2l-3.35-1-1,3.42a.54.54,0,0,1-.47.41Zm-4-9,3.87,7L20,29.32a.55.55,0,0,1,.68-.39l2.55.75-2.85-4.95a2.43,2.43,0,0,1-2.88-.16,1.39,1.39,0,0,0-1.27-.25A1.41,1.41,0,0,0,15.37,25Z"
        ></path>
        <path
          d="M6.1,34H6a.57.57,0,0,1-.47-.41l-1-3.42-3.34,1a.57.57,0,0,1-.6-.2.58.58,0,0,1,0-.63l3.87-6.74a.56.56,0,0,1,.41-.27.55.55,0,0,1,.47.17,1.38,1.38,0,0,0,2,.18h0a2.51,2.51,0,0,1,2.25-.45,2.6,2.6,0,0,1,1.7,1.58.55.55,0,0,1,0,.48L6.59,33.73A.56.56,0,0,1,6.1,34ZM5,28.91a.57.57,0,0,1,.54.41L6.27,32l3.88-7a1.43,1.43,0,0,0-.86-.66A1.39,1.39,0,0,0,8,24.57a2.43,2.43,0,0,1-2.88.16L2.29,29.68l2.55-.74A.4.4,0,0,1,5,28.91Z"
        ></path>
        <path
          d="M12.64,9.56h0a.16.16,0,0,1-.15-.16V9.08a.16.16,0,0,1,.15-.16.16.16,0,0,1,.16.16V9.4a.16.16,0,0,1-.16.16"
        ></path>
        <path
          d="M11.57,9.75h0a.16.16,0,0,1-.2-.09l-.11-.3a.16.16,0,0,1,.09-.21.15.15,0,0,1,.2.1l.11.3a.16.16,0,0,1-.09.2"
        ></path>
        <path
          d="M10.63,10.31h0a.17.17,0,0,1-.22,0l-.2-.25a.16.16,0,1,1,.24-.2l.2.24a.16.16,0,0,1,0,.23"
        ></path>
        <path
          d="M15.06,15.69h0a.16.16,0,0,1-.22,0l-.2-.25a.17.17,0,0,1,0-.22.16.16,0,0,1,.22,0l.2.25a.15.15,0,0,1,0,.22"
        ></path>
        <path
          d="M9.93,11.16h0a.16.16,0,0,1-.21.06l-.27-.16a.18.18,0,0,1-.06-.22.16.16,0,0,1,.22-.06l.27.16a.16.16,0,0,1,0,.22"
        ></path>
        <path
          d="M15.9,14.68h0a.16.16,0,0,1-.22.06l-.27-.16a.16.16,0,0,1-.05-.22.15.15,0,0,1,.21-.06l.27.16a.17.17,0,0,1,.06.22"
        ></path>
        <path
          d="M9.57,12.2h0a.17.17,0,0,1-.18.13l-.31-.06A.17.17,0,0,1,9,12.09.16.16,0,0,1,9.13,12l.31,0a.17.17,0,0,1,.13.19"
        ></path>
        <path
          d="M16.34,13.42h0a.15.15,0,0,1-.18.13l-.31-.05a.18.18,0,0,1-.13-.19.17.17,0,0,1,.19-.13l.3.06a.15.15,0,0,1,.13.18"
        ></path>
        <path
          d="M9.57,13.31h0a.17.17,0,0,1-.13.19l-.31.05A.16.16,0,0,1,9,13.42a.17.17,0,0,1,.13-.18l.31-.06a.16.16,0,0,1,.18.13"
        ></path>
        <path
          d="M9.93,14.36h0a.16.16,0,0,1,0,.22l-.27.16a.16.16,0,0,1-.22-.06.17.17,0,0,1,.06-.22l.27-.16a.15.15,0,0,1,.21.06"
        ></path>
        <path
          d="M15.9,10.84h0a.18.18,0,0,1-.06.22l-.27.16a.16.16,0,0,1-.16-.28l.27-.16a.16.16,0,0,1,.22.06"
        ></path>
        <path
          d="M16.34,12.09h0a.15.15,0,0,1-.13.18l-.3.06a.16.16,0,0,1-.06-.32l.31,0a.15.15,0,0,1,.18.13"
        ></path>
        <path
          d="M15.06,9.82h0a.15.15,0,0,1,0,.22l-.2.25a.17.17,0,0,1-.22,0,.18.18,0,0,1,0-.23l.2-.24a.15.15,0,0,1,.22,0"
        ></path>
        <path
          d="M10.63,15.21h0a.15.15,0,0,1,0,.22l-.2.25a.16.16,0,0,1-.22,0,.15.15,0,0,1,0-.22l.2-.25a.16.16,0,0,1,.22,0"
        ></path>
        <path
          d="M13.93,9.15h0a.16.16,0,0,1,.09.21l-.1.3a.16.16,0,0,1-.2.09.16.16,0,0,1-.1-.2l.11-.3a.15.15,0,0,1,.2-.1"
        ></path>
        <path
          d="M12.64,10.56a2.21,2.21,0,0,1,2.19,2.23,2.59,2.59,0,0,1-.49,1.53h0v0a6.79,6.79,0,0,0-1,2.22H12a7.08,7.08,0,0,0-1-2.24v0a2.61,2.61,0,0,1-.48-1.51,2.21,2.21,0,0,1,2.18-2.24"
        ></path>
        <path
          class="cls-4"
          d="M12.64,10.24a2.52,2.52,0,0,0-2.5,2.55,3,3,0,0,0,.56,1.74,6.21,6.21,0,0,1,1,2.38h1.88a6.24,6.24,0,0,1,1-2.37,2.94,2.94,0,0,0,.56-1.74,2.53,2.53,0,0,0-2.5-2.56"
        ></path>
        <path d="M11.68,17.83h1.88v.32H11.68Z"></path>
        <path d="M13.25,18.47H12l-.32-.32h1.88l-.31.32"></path>
        <path d="M11.69,17.19h1.87v.32H11.69Z"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MedalSvg",
};
</script>

<style></style>
