<template>
  <div id="modalDetail" @click="close">
    <div
      id="modal-idea-detail"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" @click.stop>
          <div class="modal-header bg-orange rounded-0">
            <h5 class="modal-title text-center text-white mx-auto">
              FİKRİ MÜLKİYET HAKLARI DEVİR PROTOKOLÜ
            </h5>
            <button
              @click="close"
              type="button"
              class="close"
              id="btnDetailClose"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="container mb-3 mw-100">
            <p>
              İşbu Fikri Mülkiyet Hakları Devir Protokolü (bundan böyle “<b>Protokol</b>”
              olarak anılacaktır) bir tarafta İstanbul Ticaret Sicil Müdürlüğü’nde 790489
              ticaret sicil numarası ile kayıtlı, merkezi Maslak Mahallesi Büyükdere
              Caddesi No: 249/6 Sarıyer, İstanbul adresinde bulunan
              <b
                >Doğuş Bilgi İşlem ve Teknoloji Hizmetleri Anonim Şirketi (“Doğuş
                Teknoloji”)</b
              >
              ile diğer tarafta <b>[•]</b> adresinde mukim <b>[•]</b> T.C. Kimlik Numaralı
              <b>[•] (“Çalışan”)</b> arasında <b>[•]</b> tarihinde
              <b>(“İmza Tarihi”)</b> imzalanarak yürürlüğe girmiştir.
            </p>
            <br />
            <p>
              Doğuş Teknoloji ve Çalışan işbu Protokol’de ayrı ayrı
              <b>“Taraf”</b> ve birlikte <b>“Taraflar”</b> olarak anılacaklardır.
            </p>
            <br />
            <p>
              <b>1 TANIMLAR</b>
            </p>
            <br />
            <p>
              <b>1.1 Fikri Mülkiyet Hakları</b>: Tescil ettirilmiş yahut tescil edilebilir
              olsun veya olmasın: (i) marka, logo, buluş, patent, faydalı model,
              endüstriyel tasarım, işaret ve entegre devre topografyaları dahil Türk
              Patent ve Marka Kurumu’na ya da yurtdışındaki dengi kuruluşa tescil
              edilebilecek her türlü fikri mülkiyet hakkı ile (ii) görsel, makale metin,
              banner, film, grafik, tasarım, video, bilgisayar programı, yazılım, mobil
              dijital cihaz yazılım ve uygulamaları, bunlarla ilgili kaynak kodu (source
              code), dizin (script), arayüz ve bunlara ilişkin hazırlık çalışmaları, nevi
              şahsına münhasır (sui generis) veri tabanı hakları, tasarım, grafik, resim,
              metin ve fotoğraflar da dâhil fikir ve sanat eserleri kapsamındaki tüm
              eserlere ilişkin Mali Haklar ile Manevi Haklarının kullanım yetkileri, ve
              (iii) fikri mülkiyet koruması kapsamına girsin ya da girmesin ticaret
              unvanları, isim hakları, işletme adları, ürün adları, alan adları ve
              adresleri, internet sitesi sayfaları, know-how, iş modeli, sloganlara
              ilişkin haklar, eser, fikir ve süreçler ve bunlarla ilgili her türlü
              iyileştirme ve değişiklikler ve bunlarla bağlantılı haklar dahil ve fakat
              bunlarla sınırlı olmaksızın her türlü fikri ve sınaî mülkiyet hakları (tüm
              versiyonları, değişiklikleri, gelişmeleri ve türevleri içerecek şekilde) ile
              bu hakları almak için yapılan bütün başvurular ve bu hakların yenilenmiş
              veya uzatılmış biçimlerini ifade eder.
            </p>
            <br />
            <p>
              <b>1.2 FSEK: </b>: 5846 Sayılı Fikir ve Sanat Eserleri Kanunu’nu ifade eder.
            </p>
            <br />
            <p>
              <b>1.3 Mali Haklar</b>: “Tespit”, “İşleme”, “Çoğaltma”, “Yayma”, “Temsil”,
              “İşaret Ses ve/veya Görüntü Nakline Yarayan Araçlarla Umuma İletim”,
              “Yeniden İletim”, “Kişilerin Seçtikleri Yer ve Zaman’da İletim”, “Kiralama”,
              “Ödünç Verme”, “Satma”, “Senkronizasyon”, “Derleme”, “Dağıtım”, “Aranje”,
              “Format değiştire”, “Pay Alma” ,“Kullanma”, “İndirme”, “Stream etme”,
              “Güncelleme”, “Tercüme”, “Dijitaleştirme” dahil ve ancak bunlarla sınırlı
              olmayarak FSEK (21 ve 25. maddeleri arasında ve 45. maddesinde sayılan
              haklar), 6769 sayılı Sınai Mülkiyet Kanunu ve sair mevzuatta sayılan haklar
              ile mevzuat değişiklikleriyle sayılanlara dâhil edilecek diğer her türlü
              mali sonuç doğuran tüm devredilebilir hakları ifade eder.
            </p>
            <br />
            <p>
              <b>1.4 Manevi Haklar</b>: “Umuma Arz Salahiyeti”,“Adın belirtilmesi
              salâhiyeti”, “Eserin İçeriği Hakkında Bilgi Verme Hakkı”,“Eserin Adını
              Değiştirme Hakkı”, “Eserde Değişiklik Yapılmasını Men Etme Hakkı”, “Eserin
              Sahibi Olarak Tanıtılma Hakkı”, dahil ve ancak bunlarla sınırlı olmayarak
              FSEK (14 ve 17. maddeleri arasında sayılan haklar), 6769 sayılı Sınai
              Mülkiyet Kanunu ve sair mevzuatta sayılan haklar ile mevzuat
              değişiklikleriyle sayılanlara dâhil edilecek diğer her türlü mali sonuç
              doğuran tüm devredilebilir hakları ifade eder.
            </p>
            <br />
            <p>
              <b>1.5 Proje</b>: Doğuş Teknoloji Inovasyon Platformu (“Platform”)
              kapsamında, münhasıran Doğuş Teknoloji’nin takdirine bağlı olarak
              ürün/hizmete dönüştürülmesine karar verilen Çalışan tarafından Platform’a
              girilen ekte adı ve özeti bulunan proje fikri ve/veya Çalışan tarafından
              oluşturulacak Yazılımlar ve geliştirilecek projeyi ifade eder.
            </p>
            <br />
            <p>
              <b>1.6 Yazılım</b>: Platform kapsamındaki çalışmalar çerçevesinde işbu
              Sözleşme ile Şirket’e devir ve teslim edilecek olan,
              <br />
              <br />
              <span>
                ✓ Yazılım ve yazılımın çalışması için gerekli her türlü hazırlık
                tasarımları, hazırlık çalışması, modül, emir dizgesi ve alt yapısı, kaynak
                kodları, nesne kodları, api, psd dosyaları. yazılım ve yazılımın çalışması
                için gerekli her türlü hazırlık tasarımları, hazırlık çalışması, modül,
                emir dizgesi ve alt yapısı, kaynak kodları, nesne kodları, api, psd
                dosyaları.
              </span>
              <br />
              <br />
              <span>
                ✓ Okunabilir, basılı veya elektronik yazılım ile ilgili her türlü doküman,
                her türlü içerik, unsur, bölüm, kullanıcı kılavuzları.
              </span>
              <br />
              <br />
              <span>
                ✓ Bunların herhangi bir parçası, modülü, arayüz tasarımları ve unsuru
                kapsamaktadır.
              </span>
            </p>
            <br />
            <p>
              <b>1.7 Kaynak Kodları</b>: Çalışan tarafından Proje kapsamında oluşturulan
              Yazılım’ın oluşturulması ve geliştirilmesinde kullanılan kaynak kodlarıdır.
            </p>
            <br />
            <p>
              <b>2 PROTOKOLÜN KONUSU</b>
            </p>
            <br />
            <p>
              İşbu Protokol’ün konusu, Çalışan’ın Proje’ye ilişkin Mali Haklar ve Manevi
              Haklar dahil tüm Fikri Mülkiyet Hakları’nın, Doğuş Teknoloji’ye hiçbir
              kısıtlama olmadan kullanması, işlemesi, ticarileştirmesi ve tasarruf etmesi
              için yer, süre, içerik, mecra, format, bası ve sayı sınırı bulunmaksızın bu
              hakları üçüncü kişilere devretme ve/veya lisans verme yetkisi de dahil olmak
              üzere FSEK Madde 48/1 uyarınca ve FSEK Madde 52’ye uygun olarak münhasıran
              Doğuş Teknoloji’ye devredilmesi ve Kaynak Kodları’nın Doğuş Teknoloji’ye
              teslim edilmesidir.
            </p>
            <br />
            <p>
              <b>3 DEVİR ve TESLİM</b>
            </p>
            <br />
            <p>
              <b>3.1.</b> Doğuş Teknoloji, İşveren sıfatlıya Yazılım ve Kaynak Kodları
              dahil Proje üzerinde Kanun gereği tüm Mali Hakları kullanım yetkisinin
              münhasıran tek sahibidir. Bununla beraber herhangi bir şüpheye yer vermek
              adına Taraflar, işbu Protokol ile Çalışan, başta Yazılım ve Kaynak Kodları
              olmak üzere, Proje kapsamında geliştirdiği tüm fikri ürünlere dair Fikri
              Mülkiyet Hakları’nı, yer, süre, içerik, mecra, format, bası ve sayı sınırı
              bulunmaksızın, üçüncü kişilere devir ve/veya lisans yetkisini de kapsar
              şekilde bedelsiz olarak Doğuş Teknoloji’ye ait olduğu ve bu hakların Doğuş
              Teknoloji’ye tahsis edilmesi konusunda mutabık kalmıştır:
            </p>
            <br />
            <p>
              <b>3.1.1.</b> Çalışan, başta Yazılım ve Kaynak Kodları olmak üzere, Proje’ye
              ilişkin bütün fikri ürünlere dair tüm Mali Hakları yer, süre, içerik, mecra,
              format, bası ve sayı sınırı bulunmaksızın, üçüncü kişilere devir ve/veya
              lisans yetkisi dahil münhasıran, gayri kabili rücu olarak Doğuş Teknoloji’ye
              ait olduğunu ve bu hakların tahsisi için herhangi bir devir işlemi gerekmesi
              halinde, bu hakları Doğuş Teknoloji’ye herhangi bir koşul ileri sürmeksizin
              devireceğini kabul, beyan ve taahhüt etmektedir.
            </p>
            <br />
            <p>
              <b>3.1.2.</b> Çalışan, işbu Protokol ile beraber, başta Yazılım ve Kaynak
              Kodları olmak üzere, Proje’ye ilişkin tüm fikri ürünlere tüm Manevi Hakları
              kullanım yetkisini yer, süre, içerik, mecra, format, bası ve sayı sınırı
              bulunmaksızın, üçüncü kişilere devir ve/veya lisans yetkisi dahil
              münhasıran, gayri kabili rücu olarak Doğuş Teknoloji’ye devrettiğini kabul,
              beyan ve taahhüt etmektedir.
            </p>
            <br />
            <p>
              <b>3.2.</b> İşbu Protokol’de öngörülen devir kapsamında Çalışan, ücretsiz
              olarak Proje’ye ait Kaynak Kodlarını tam, eksiksiz ve çalışır biçimde en
              güncel haliyle Doğuş Teknoloji’ye teslim edecek; Doğuş Teknoloji de Kaynak
              Kodları tam, eksiksiz ve çalışır biçimde en güncel haliyle teslim alacaktır.
            </p>
            <br />
            <p>
              <b>4 ÇALIŞAN’IN BEYAN VE TAAHHÜTLERİ</b>
            </p>
            <br />
            <p>
              <b>4.1</b> Çalışan, işbu Protokol’ü imzalamaya ve Proje üzerindeki tüm Fikri
              Mülkiyet Haklarını işbu Protokol uyarınca devretmeye tamamen yetkili
              olacağını, Doğuş Teknoloji tarafından sağlanacak materyaller ve platformlar
              haricinde, kullandığı ve kullanacağı her türlü materyalin dışında Proje’nin
              (Yazılım ve Kaynak Kodu dahil) bütünüyle orijinal ve kendisince yaratılmış
              olacağını, işbu Protokol kapsamındaki devrin herhangi bir üçüncü kişi
              hakkını ihlal etmeyeceğini, Proje’nin herhangi bir başka kişi veya kuruma
              devretmediğini/devretmeyeceğini veya kullanım izni
              vermediğini/vermeyeceğini, eğer Proje üzerinde başkaca hak sahibi olanlar
              var ise gerekli her türlü iznin, işbu sözleşme kapsamındaki devir haklarıyla
              beraber yasalara uygun olarak alındığını/alınacağını kabul, beyan ve taahhüt
              eder.
            </p>
            <br />
            <p>
              <b>4.2</b> Çalışan, işbu Protokol ile Proje’nin hiçbir kısıtlama olmaksızın
              kullanılması ve ticari faaliyete konu edilmesi konusunda [Çalışan’ın İş
              Sözleşmesi’nin gereği olarak] Doğuş Teknoloji’nin münhasıran yetkili
              olacağını, anılan hakların süre, yer, sayı, mecra ve içerik itibariyle
              sınırsız olarak, üçüncü kişilere devir ve/veya lisans yetkisini de kapsar
              şekilde münhasıran Doğuş Teknoloji’ye ait olacağını kabul, beyan ve taahhüt
              eder.
            </p>
            <br />
            <p>
              <b>4.3</b> Doğuş Teknoloji, Proje konusu fikri ürünü kullanırken Çalışan’ın
              ismini belirtme yükümlülüğü altında olmayıp, bu fikri ürünü umuma arz etme
              ve üzerinde değişiklik yaparak kullanmak da dahil her türlü tasarruf
              yetkisine sahip olacaktır. Çalışan, Doğuş Teknoloji’nin bu hakları
              kullanmasına karşı çıkmayacağını ve bir hak talebinde bulunmayacağını kabul,
              beyan ve taahhüt eder.
            </p>
            <br />
            <p><b>4.4</b> Çalışan;</p>
            <br />
            <p>
              <b>4.4.1</b> Meydana getirilen/ getirilecek Proje, eser vasfını haiz her
              türlü fikir ve sanat ürününe ilişkin olarak üçüncü kişilerin herhangi bir
              zaman hak sahipliği iddia etmesi durumunda ve bu iddialarını ispatlamaları
              durumunda, Doğuş Teknoloji’nin bu nedenle oluşan doğrudan zararını tazmin
              edeceğini;
            </p>
            <br />
            <p>
              <b>4.4.2</b> Fikri Mülkiyet Haklarının Doğuş Teknoloji’ye devretmiş
              olduğunu; anılan hakların yurt içi ve yurt dışında yer, süre, içerik, mecra
              ve sayı sınırı olmaksızın Doğuş Teknoloji’ye ait olduğunu, bu keyfiyetin
              aleniliği teminen Fikir ve Sanat Eserlerinin Kayıt ve Tescili Hakkında
              Yönetmelik (Resmi Gazete Tarihi ve Sayısı: 17/05/2006-26171) gereğince
              Çalışan’ın tüm bu hakların tasarruf ve kullanım haklarının tamamıyla Doğuş
              Teknoloji lehine tescili için gerekli tüm yetkiyi de Doğuş Teknoloji’ye
              verdiğini ve bu Yönetmelik gereğince yapması gereken iş, işlemleri eksiksiz
              yerine getireceğini, gerekli taahhütnameleri vermekle yükümlü olduğunu,
              Doğuş Teknoloji’nin know-how ve ticari bilgisini içeren kısımları Yönetmelik
              doğrultusunda ve uyarınca gizli tutulacağını;
            </p>
            <br />
            <p>
              <b>4.4.3</b> Proje’nin veya üretilen sair eserin hiçbir kısıtlama olmaksızın
              kullanılması; ticari faaliyete konu edilmesi konusunda kendisinin münhasıran
              yetkili olduğunu; anılan hakların süre, yer, sayı, bası, format, mecra ve
              içerik itibariyle sınırsız olarak, üçüncü kişilere devir/ lisans yetkisini
              de kapsar şekilde münhasıran Doğuş Teknoloji’ye ait olacağını;
            </p>
            <br />
            <p>
              <b>4.4.4</b> İşbu Protokol’de yer verilen Kaynak Kodları üzerindeki
              ilerideki oluşacak yeni Fikri Mülkiyet Haklarını dahi devrine ilişkin
              kendisi tarafından ileride ayrıca imzalanması talep edilen her türlü
              muvafakatname ve sair belgeyi de Doğuş Teknoloji tarafından talepte
              bulunulmasını takiben en geç beş iş günü içinde imzalayacağını;
            </p>
            <br />
            <p>
              <b>4.4.5</b> Proje veya üretilen sair eserin, içeriğin/ tasarımın tamamını
              ya da bir kısmını, kendisinin herhangi bir Fikri Mülkiyet Hakkını kendi nam
              ve hesabına kullanma hakkı bulunmadığını/bulunmayacağını;
            </p>
            <br />
            <p>
              <b>4.4.6</b> Hiçbir şüpheye mahal vermemek adına, Platform kapsamında
              meydana getirilmiş Yazılım ve esere Kaynak Kodlarının ve bunun geliştirilmiş
              versiyonlarının tamamını ya da bir kısmını her şekilde işleme; asıl ve/veya
              işlenecek halini/ tamamını veya bir kısmını doğrudan veya dolaylı çoğaltma;
              herhangi bir şekil veya yöntemle yayma; doğrudan doğruya veya işaret, ses
              veya resim nakline yarayan araçlarla umumî mahallerde okumak, çalmak,
              oynamak ve göstermek gibi temsil suretiyle faydalanma; dijital ortamlarda
              ve/ veya mobil araçlarla kullanma, umuma iletme ve üçüncü kişilerin
              erişimine sunma; İmza Tarihinde bilinen ve İmza tarihinden sonra
              geliştirilecek teknik olanaklarla üretilecek veri taşımaya yarayan araçlara
              kaydetme; Yazılım ve Kaynak Kodunun tamamını veya bir kısmını her türlü
              görsel-işitsel eser içerisinde kullanma ve İmza tarihinde bilinen veya
              ileride geliştirilecek yöntemlerle, işaret-ses ve/veya görüntü nakline
              yarayan araçlarla veya yeniden yayınlanmak suretiyle umuma iletme hakları
              dahil tüm Mali Haklarının süresiz olarak, yurtiçi ve yurtdışında herhangi
              bir yer, içerik, mecra ve sayı sınırlamasına tâbi olmaksızın ve her türlü
              hakkı üçüncü kişilere süreli-süresiz, bedelli-bedelsiz devretme ve lisans
              verme hakları da dâhil olmak üzere, gayri kabili-i rücu olarak Doğuş
              Teknolojiye devrettiğini;
            </p>
            <br />
            <p>
              <b>4.4.7</b> Aynı şekilde 6769 sayılı Sınai Mülkiyet Kanunu kapsamında
              korunan tasarım, marka, -olması durumunda- patent ve faydalı model
              bakımından tüm sınai/fikri mülkiyet haklarının yukarıda belirtilen şekilde
              teslimle birlikte Doğuş Teknolojiye ait olacağı, bu hakların da tamamen
              Doğuş Teknolojiye devredildiğini
            </p>
            <br />
            <p>kabul, beyan ve taahhüt eder.</p>
            <br />
            <p>
              <b>5 TEBLİGATLAR</b>
            </p>
            <br />
            <p>
              Taraflar işbu Protokol’de yazılı adreslerin geçerli tebligat adresleri
              olduğunu, anılan adreslerde meydana gelebilecek değişiklikler karşı tarafa 7
              gün içinde yazılı olarak bildirilmedikçe bu adreslere yöneltilecek
              tebligatların hukuken geçerli bir tebligatın tüm neticelerini doğuracağını
              peşinen kabul etmişlerdir.
            </p>
            <br />
            <p>
              <b>6 AYRILABİLİRLİK</b>
            </p>
            <br />
            <p>
              İşbu Protokol’ün herhangi bir hükmünün iptal edilmesi veya Türkiye
              Cumhuriyeti kanunlarına aykırı olması halinde bu durum, geçersiz veya ifa
              edilemez sayılan hükümler dışındaki Protokol hükümlerini geçersiz kılmaz ve
              aynı şekilde, geçersiz veya ifa edilemez sayılan hükümlerin, uygulanabilmesi
              mümkün başka şahıs veya durumlara uygulanmasını etkilemez. Protokol’ün
              herhangi bir hükmünün bu şekilde iptal edilmesi veya geçersiz olması
              halinde, Protokol’ün diğer hükümleri, kanunların izin verdiği ölçüde geçerli
              ve ifa edilebilir olacaktır.
            </p>
            <br />
            <p>
              <b>7 UYUŞMAZLIKLARIN ÇÖZÜMÜ</b>
            </p>
            <br />
            <p>
              İşbu Protokol’den doğabilecek her türlü uyuşmazlığın çözümünde İstanbul
              (Çağlayan) Mahkemeleri ile İcra Müdürlükleri yetkilidir.
            </p>
            <br />
            <p>
              <b>Ek-1: Proje Adı ve Özeti</b>
            </p>
            <br />
            <div>
              <div class="ml-5" style="max-width: 250px; float: left">
                <p>
                  <b>DOĞUŞ BİLGİ İŞLEM VE TEKNOLOJİ HİZMETLERİ A.Ş.</b>
                </p>
              </div>
              <div class="ml-5" style="max-width: 250px; float: left">
                <p>
                  <b>[ÇALIŞAN]</b>
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Undertaking",
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
  },
  destroyed() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
  },
};
</script>

<style></style>
