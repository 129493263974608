<template>
  <aside class="col-12 col-lg-4 pl-xl-0 pt-aside">
    <!--FİKRİNİ PAYLAŞ BUTTON-->
    <ShareIdea @share-idea="isShareIdea = $emit" />
    <!--ÖNERİNİ PAYLAŞ Card Display None-->
    <!-- <ShareSuggestion /> -->

    <!--ÜRÜN FİKRİNİ PAYLAŞ Card-->
    <ProductIdea
      v-if="user.Data.CompanyConfiguration.IsArgeVisible"
      :userDetail="user.Data"
      @shareProductModal="isShareSuggestion = $event"
    />

    <!--INVENDO FİKRİNİ PAYLAŞ Card-->
    <InvendoIdea
      v-if="user.Data.CompanyConfiguration.IsInvendoVisible"
      :userDetail="user.Data"
      @shareInvendoModal="isSInvendoIdeas = $event"
    />

    <!-- Proje Havuzu Button-->
    <!-- <ProjectpoolBtn :userDetail="user.Data" @on-click-project-pool="onClickProjectPool"/> -->

    <!--MinDO Card-->
    <MindoCard v-if="user.Data.CompanyConfiguration.IsMindoVisible" />

    <!--En Cok Fikir Veren Kisiler Card-->
    <PeopleIdea />

    <!-- //Modals -->
    <ShareIdeas
      v-if="isShareIdea"
      @close="isShareIdea = $event"
      :editNewestsData="editNewestsData"
      isIdea="main"
      :isDraft="true"
    />
    <AutomotiveProduct
      :isDraft="true"
      v-if="isShareSuggestion"
      @close="isShareSuggestion = $event"
    />
    <transition name="fade">
      <Undertaking
        v-if="idUndertakingCheck"
        @close="idUndertakingCheck = $event"
      />
    </transition>
    <transition name="fade">
      <InvendoIdeas
        :isDraft="true"
        v-if="isSInvendoIdeas"
        @close="isSInvendoIdeas = $event"
      />
    </transition>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

import PeopleIdea from "./PeopleIdea.vue";
import MindoCard from "./MindoCard.vue";
import InvendoIdea from "./InvendoIdea.vue";
import ProductIdea from "./ProductIdea.vue";
//import ProjectpoolBtn from "./ProjectpoolBtn.vue";
//import ShareSuggestion from "./ShareSuggestion.vue";
import ShareIdeas from "../Modals/ShareIdeas.vue";

import AutomotiveProduct from "../Modals/AutomotiveProduct.vue";
import InvendoIdeas from "../Modals/InvendoIdeas.vue";
import Undertaking from "../Modals/Undertaking";
import ShareIdea from "./ShareIdea.vue";
export default {
  name: "HomeCards",
  data() {
    return {
      isShareIdea: false,
      isShareSuggestion: false,
      isSInvendoIdeas: false,
      idUndertakingCheck: false,
      editNewestsData: null,
    };
  },
  components: {
    PeopleIdea,
    MindoCard,
    InvendoIdea,
    ProductIdea,
    //ShareSuggestion,
    InvendoIdeas,
    AutomotiveProduct,
    ShareIdea,
    ShareIdeas,
    //ProjectpoolBtn,
    Undertaking,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  watch: {
    isShareIdea() {
      if (!this.isShareIdea) this.editNewestsData = null;
    },
  },
  methods: {
    // onClickProjectPool(){
    //   this.$router.push("/project-pool")
    // }
  },
};
</script>

<style></style>
