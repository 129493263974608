<template>
  <button
    id="btn-share-arge"
    class="btn-innovation btn-innovation-left bg-orange d-lg-inline-block icon-right tooltipper"
    @click.stop="shareProductModal"
    title="Maliyet tasarrufu, gelirlerin artışı, müşteri memnuniyeti veya kalite boyutlarında ölçülebilir fayda sağlayan süreç iyileştirme projelerinizi buradan paylaşabilirsiniz."
  >
    {{ userDetail.CompanyConfiguration.ArgeButtonText }}
    <img
      src="../../assets/images/fuzeicon.png"
      alt="Fikrini Paylaş"
      style="
        padding-right: 40px;
        position: absolute;
        right: 0;
        top: 5px;
        max-width: 100%;
        max-height: 100%;
      "
    />
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductIdea",
  methods: {
    shareProductModal() {
      this.$emit("shareProductModal", true);
    },
  },
  props: ["userDetail"],
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
};
</script>

<style></style>
